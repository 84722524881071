// 导出 Vuex 模块对象
export default {
  namespaced: true, // 使用命名空间，这样在全局状态下访问此模块的状态和mutation需要加上模块名
  state: {
    isLoggedIn: false,
    userInfo: null,
    isVip: false,
    isInfoComplete: false, // 用户信息是否完整
  },
  mutations: {
    SET_TOKEN(state, token) {
      // 更新 state 中的 token
      state.token = token;
    },
    SET_LOGGED_IN(state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn;
    },
    SET_USER_INFO(state, userInfo) {
      state.userInfo = userInfo;
    },
    SET_VIP_STATUS(state, hasVip) {
      state.isVip = hasVip;
    },
    SET_INFO_COMPLETE_STATUS(state, isInfoComplete) {
      state.isInfoComplete = isInfoComplete;
    },
  },
  actions: {
    login({ commit }, { userInfo, token }) {
      commit('SET_USER_INFO', userInfo);
      commit('SET_TOKEN', token);
      commit('SET_LOGGED_IN', true);
      checkUserInfoCompleteness(commit, userInfo);
      // 这里可以添加其他登录后需要执行的操作，比如获取用户数据等
    },
    restoreLogin({ commit }, { userInfo, token }) {
      // 使用从 localStorage 恢复的数据来更新状态
      commit('SET_USER_INFO', userInfo);
      commit('SET_TOKEN', token);
      commit('SET_LOGGED_IN', true);
      checkUserInfoCompleteness(commit, userInfo);
    },
    logout({ commit }) {
      commit('SET_USER_INFO', null);
      commit('SET_TOKEN', null);
      commit('SET_LOGGED_IN', false);

      // 清除存储中的token和用户信息（如果需要）
      localStorage.clear();
    },
    async updateUserInfo({ commit }, userInfo) {
      // 在这里可以添加额外的逻辑，比如检查用户信息是否合法等
      commit('SET_USER_INFO', userInfo); // 更新用户信息
      checkUserInfoCompleteness(commit, userInfo);
    },
  },
  getters: {
    isLoggedIn: state => state.isLoggedIn,
    userInfo: state => state.userInfo,
    isVip: state => state.isVip,
    isInfoComplete: state => state.isInfoComplete,
  }
};
function checkUserInfoCompleteness(commit, userInfo) {
  
  let time = Date.now()/1000;
  time = Math.floor(time);
  if (!userInfo.vip_expirationtime || (userInfo.vip_expirationtime <= time)) {
    commit('SET_VIP_STATUS', false); // 用户没有 VIP
  }else{
    commit('SET_VIP_STATUS', true); // 用户有 VIP
  }

  if (!userInfo.nickname) {
    commit('SET_INFO_COMPLETE_STATUS', false); // 用户信息不完整
  }else{
    commit('SET_INFO_COMPLETE_STATUS', true); // 用户信息完整
  }
}