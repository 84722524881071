<template>
  <div class="main">
    <div class="content">
      <div class="special-name">{{ data.name }}</div>
      <div class="flex-between">
        <div class="left">
          <div class="tabbar">
            <div :class="['tabbar-item',current===0?'seleced':'']" @click="current = 0">
              <el-icon size="24" :color="current===0?'#fff':'#12A3EA'">
                <Document />
              </el-icon>
              <p>基本信息</p>
            </div>
            <div :class="['tabbar-item',current===1?'seleced':'']" @click="current = 1">
              <el-icon size="24" :color="current===1?'#fff':'#12A3EA'">
                <School />
              </el-icon>
              <p>开设院校</p>
            </div>
            <div :class="['tabbar-item',current===2?'seleced':'']" @click="current = 2">
              <el-icon size="24" :color="current===2?'#fff':'#12A3EA'">
                <Notebook />
              </el-icon>
              <p>开设课程</p>
            </div>
          </div>
          <!-- <sidebar></sidebar> -->
        </div>
        <div class="right">
          <div v-if="current === 0">
            <special-basic :data="data"></special-basic>
          </div>
          <div v-if="current === 1">
            <special-school :id="id"></special-school>
          </div>
          <div v-if="current === 2">
            <special-course :data="data.course"></special-course>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getSpecialDetail } from '@/api/home';
// import Sidebar from '@/components/Sidebar.vue';
import SpecialBasic from '@/components/SpecialBasic.vue';
import SpecialSchool from '@/components/SpecialSchool.vue';
import SpecialCourse from '@/components/SpecialCourse.vue';

export default {
  components: {
    // Sidebar,
    SpecialBasic,
    SpecialSchool,
    SpecialCourse,
  },
  data() {
    return {
      current: 0,
      id: null, // Vue 2 中通常使用 null 或具体值来初始化
      data: {},
    };
  },
  created() {
    this.handleTop();
    // 在 Vue 2 中，你需要从 $route 中获取 params，但通常 params 用于命名路由的动态片段和星号片段
    // 如果你是通过 query 传递的 id，则应该使用 this.$route.query.id
    // 假设这里确实是通过 query 传递的
    this.id = this.$route.query.id; // 或者从 $route.params 中获取，具体取决于你的路由配置
    this.fetchSpecialDetail(this.id);
  },
  methods: {
    handleTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // 可选的，使用平滑滚动效果
      });
    },
    async fetchSpecialDetail(id) {
      try {
        const response = await getSpecialDetail({ id: id });
        if (response.code === 1) {
          this.data = response.data; // 在 Vue 2 中，直接赋值给 data 对象的属性
        }
      } catch (error) {
        // 处理错误
      }
    },
  },
};
</script>
<!--声明语言，并且添加scoped-->
<style lang="scss" scoped>
  .main{
    background-color: #F5F5F5;
    padding: 43px 0 40px;
  }
  .content{
    width: 1180px;
    margin: 0 auto;
    background-color: #fff;
    padding: 30px 40px 60px;
    box-sizing: border-box;
  }
  .special-name{
    font-size: 40px;
    text-align: center;
    margin-bottom: 20px;
  }
  .left{
    width: 305px;
  }
  .tabbar{
    width:100%;
    margin-bottom: 30px;
    &-item{
      min-height: 50px;
      display: flex;
      align-items: center;
      border: 2px solid #DEE7F0;
      border-bottom: none;
      padding: 10px 20px;
      box-sizing: border-box;
      cursor: pointer;
      &:last-child{
        border-bottom: 2px solid #DEE7F0;
      }
      & > p{
        display: block;
        flex: 1;
        margin-left: 20px;
      }
    }
  }
  .seleced{
    background-color: #F15F2D !important;
    color: #fff !important;
  }
  .right{
    width: 100%;
  }
</style>