<template>
  <div class="special-basic">
    <div class="label">
      <div class="label-item">
        <span>学历层次</span>
        <span>{{ data.type }}</span>
      </div>
      <div class="label-item">
        <span>专业代码</span>
        <span>{{ data.code }}</span>
      </div>
      <div class="label-item">
        <span>门类</span>
        <span>{{ data.lv1_name }}</span>
      </div>
      <div class="label-item">
        <span>专业类</span>
        <span>{{ data.lv2_name }}</span>
      </div>
    </div>
    <div class="introduce">
      <div class="introduce-header">
        <p class="introduce-header-title">专业介绍</p>
      </div>
      <div class="introduce-content">
        <div v-html="data.content"></div>
      </div>
    </div>
    <div class="close">
      <div class="introduce-header">
        <p class="introduce-header-title">相近专业</p>
      </div>
      <ul class="introduce-content flex-wrap">
        <li v-for="item in data.similar">{{ item }}</li>
      </ul>
    </div>
    <div class="close">
      <div class="introduce-header">
        <p class="introduce-header-title">该专业学生考研方向</p>
      </div>
      <ul class="introduce-content flex-wrap">
        <li v-for="item in data.postgraduate_direction">{{ item }}</li>
      </ul>
    </div>
    <div class="close">
      <div class="introduce-header">
        <p class="introduce-header-title">已毕业人员从业方向</p>
      </div>
      <ul class="introduce-content flex-wrap">
        <li v-for="item in data.employment_direction">{{ item }}</li>
      </ul>
    </div>
  </div>
</template>
  
<script>
  import { ref, onMounted } from 'vue';
  export default {
    name: 'SpecialBasic',
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    setup(props){
     
      

      onMounted(() => {
      });

      return {
      }
    }
  }
</script>
<style lang="scss" scoped>
  .special-basic{
    padding-top: 40px;
  }
  .label{
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    &-item{
      // margin-right: 50px;
      & > span:first-child{
        padding: 4.5px 8px;
        border: 1px solid #F15F2D;
        border-radius: 5px;
        margin-right: 10px;
        background-color: #FFE7DF;
      }
    }
  }
  .introduce{
    width: 100%;
    margin-top: 50px;
    &-header{
      display: flex;
      align-items: flex-end;
      border-bottom: 1px solid #DCDCDC;
      padding-bottom: 9px;
      &::before{
        content: '';
        background: #F15F2D;
        width: 3px;
        height: 20px;
        border-radius: 1.5px;
        margin: 0 4px;
      }
      &-title{
        font-family: 'PingFang SC';
        font-size: 18px;
        font-weight: normal;
        line-height: 20px;
        letter-spacing: 0em;
        color: #343434;
        
      }
    }
    &-content{
      padding: 20px 14px;
      font-size: 14px;
      line-height: 30px;
      & > li{
        margin-right: 20px;
        &:last-child{
          margin-right: 0;
        }
      }
    }
  }
  .flex-wrap{
    display: flex;
    flex-wrap: wrap;
  }
</style>
  