import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);
import App from './App.vue'
import router from './router'
import store from './store'; // 导入根store
import { storage } from '@/utils/storage';
import '@/styles/reset.css' //初始化样式
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import BaiduMap from 'vue-baidu-map'
Vue.use(ElementUI);
import 'amfe-flexible'
import './assets/css/index.css'

import * as filters from '@/filters'

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})


Vue.config.productionTip = false


// Vue.use(BaiduMap, {
//   // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
//   ak: '1g4XwF7IIt5CsRZKqySXxwNiz43AfF4W'
// })

new Vue({
  store,
  created() {
    const userInfo = storage.get('userInfo');
    const token = storage.get('token');
    if (userInfo && token) {
      // 检查userInfo是否是一个字符串
      if (typeof userInfo === 'string') {
        // 如果是字符串，则解析它
        userInfo = JSON.parse(userInfo);
      }
      // 然后将解析后的userInfo和token传递给action
      this.$store.dispatch('user/restoreLogin', { userInfo, token });
    }
  },
  router,
  render: h => h(App),
}).$mount('#app')

router.afterEach((to, from, next) => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0
})
