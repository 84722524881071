<template>
  <div>
  <div class="school-list">
    <template v-if="data">
      <div class="item" v-for="(item,index) in data" :key="index" @click="toInfo(item.id)">
        <div class="logo">
          <img :src="item.logo" alt="">
        </div>
        <div class="info">
          <div class="name">{{ item.name }}</div>
          <div class="other">
            <span>
              <!-- <font-awesome-icon :icon="['fas', 'map-marker-alt']" class="icon"/> -->
              {{ item.province_name }}
            </span>
            <span>
              主管部门：{{ item.belong }}
            </span>
          </div>
          <div class="tag">
            <span v-for="value in item.tag">{{ value }}</span>
          </div>
          <div class="rate" v-if="item.satisfaction > 0">
            满意度
            <el-rate
              v-model="item.satisfaction"
              size="small"
              :colors="['#ff0000','#ff0000','#ff0000']"
              disabled
              show-score
              text-color="#ff9900"
              score-template="{value}"
            />
          </div>
        </div>
      </div>
    </template>
    <div v-else class="nodata">
      <img src="@/assets/img/no_data.svg">
    </div>
  </div>
  
  <div class="pagination">
    <el-pagination
      v-model:currentPage="currentPage"
      v-model:pageSize="pageSize"
      :small="small"
      :disabled="disabled"
      :background="background"
      prev-text="上一页"
      next-text="下一页"
      layout="prev, pager, next, jumper"
      :total="total"
      @current-change="handleCurrentPageChange"
    />
  </div>
</div>
</template>
  
<script>
 export default {
    name: 'SchoolList',
    components: {
      // 你的组件
    },
    props: {
      data: {
        type: [Array, String],
      },
      total: {
        type: Number,
      },
      pageSize: {
        type: Number,
      },
      currentPage: {
        type: Number,
      },
    },
    data() {
      return {
        small: false,
        disabled: false,
        background: true,
        // Vue 2 中没有 ref，所以直接使用 data
      };
    },
    methods: {
      handleCurrentPageChange(newPage) {
        // 触发自定义事件
        this.$emit('returnCurrentPage', newPage);
      },
      toInfo(id) {
         // 构建带有查询参数的路由路径
        const baseUrl = this.$router.resolve({ name: 'schoolInfo' }).href;
        const queryParams = `?id=${encodeURIComponent(id)}`;
        const fullUrl = `${baseUrl}${queryParams}`;

        // 在新窗口打开带有查询参数的路径
        window.open(fullUrl, '_blank');
        // Vue 2 中使用 this.$router 进行编程式导航
        // this.$router.push({ name: 'schoolInfo', params: { id: id } });
        // 注意：使用 params 时，通常与命名视图或路由的 children 一起使用
        // 如果你只是要传递参数到一个普通的路由，可能需要使用 query 而不是 params
        // 例如：this.$router.push({ name: 'school-info', query: { id: id } });
      },
    },
    // Vue 2 中没有 emits 选项，但你可以自由地通过 this.$emit 触发自定义事件
  }
</script>
<style lang="scss" scoped>
  .school-list{
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 1px;
    font-size: 14px;
    padding: 41px 28px 23px 28px;
    box-sizing: border-box;
    color: #8E8E8E;
    display: flex;
    flex-wrap: wrap;
  }
  .item{
    display: flex;
    width: calc(50% - 24px);
    min-height: 75px;
    margin-top: 45px;
    cursor: pointer;
    &:nth-child(-n+2){
      margin-top: 0;
    }
    &:nth-child(2n){
      margin-left: auto;
    }
    .logo{
      width: 72px;
      height: 72px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 28px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .info{
      position: relative;
      flex: 1;
      .name{
        width: 130px;
        font-size: 16px;
        line-height: 18px;
      }
      .other{
        margin-top: 10px;
        font-size: 12px;
        line-height: 12px;
        .icon{
          margin-right: 5px;
        }
        span:nth-child(n+2)::before{
          content: '|';
          margin:0 10px;
        }
      }
      .tag{
        margin-top: 10px;
        font-size: 12px;
        span:nth-child(n+2)::before{
          content: '|';
          margin:0 10px;
        }
      }
      .rate{
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        font-size: 10px;
        line-height: 10px;
        padding: 4px 4px 4px 7px;
        background-color: #FFE7DF;
        border-radius: 50px 0 0 50px;
      }
    }
  }
  // .rate :deep(.el-rate) {
  //   height: 10px;
  // }
  .rate :deep(.el-rate__icon) {
    margin-right: 0;
  }
  .rate :deep(.el-rate__text) {
    font-size: 10px;
  }
  .pagination{
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
  .nodata{
    text-indent: 15px;
    color: #888;
    width: 100%;
    height: 80px;
    line-height: 80px!important;
    border: 1px solid #e4e4e4;
    text-align: center;
    font-size: 17px;
    font-weight: 200!important;
    img{
      width: 114px;
      aspect-ratio: auto 114 / 30;
      height: 30px;
      vertical-align: middle !important;
    }
  }
</style>
  