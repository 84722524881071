<template>
  <div class="special-course">
    <div class="close">
      <div class="introduce-header">
        <p class="introduce-header-title">开设专业课程</p>
      </div>
      <ul class="introduce-content" v-if="data && data.length > 0">
       <li class="introduce-content-header">
        <div class="main-center cross-center">课程名称</div>
        <div class="main-center cross-center">课程难易度</div>
        <div class="main-center cross-center flex-column">
          <p>课程实用性</p>
          <p>(于工作、个人成长)</p>
        </div>
       </li>
       <li class="data-item" v-for="(item,index) in data" :key="index">
        <div class="course-name main-center cross-center">{{ item.name }}</div>
        <div class="main-center cross-center">
          <div class="difficulty-progress cross-center">
            <el-progress :percentage="item.difficulty_percentage" :show-text="false"/>
            <span class="percentage">{{ item.difficulty }}</span>
            <!-- <span class="people">(17人)</span> -->
          </div>
        </div>
        <div class="main-center cross-center">
          <div class="difficulty-progress cross-center">
            <el-progress :percentage="item.practicality_percentage" :show-text="false"/>
            <span class="percentage">{{ item.practicality }}</span>
            <!-- <span class="people">(17人)</span> -->
          </div>
        </div>
       </li>
      </ul>
      <div class="nodata" v-else>
          <img src="@/assets/img/no_data.svg">
      </div>
    </div>

  </div>
</template>
  
<script>
  export default {
  name: 'SpecialCourse',
  props: {
    data: []
  },
  methods: {
    format() {
      return '测试';
    }
  },
  // Vue 2 不需要 setup 函数，因为所有逻辑都通过选项（如 data, computed, methods, watch 等）来组织
  // onMounted 钩子在 Vue 2 中对应的是 mounted 钩子
  mounted() {
    // 这里可以执行一些在组件挂载后需要执行的逻辑
    // 但在这个例子中，我们不需要在 mounted 钩子中做任何特别的事情
  }
};
</script>
<style lang="scss" scoped>
  .special-course{
    padding-top: 20px;
  }
  .introduce{
    width: 100%;
    margin-top: 50px;
    &-header{
      display: flex;
      align-items: flex-end;
      border-bottom: 1px solid #DCDCDC;
      padding-bottom: 9px;
      &::before{
        content: '';
        background: #F15F2D;
        width: 3px;
        height: 20px;
        border-radius: 1.5px;
        margin: 0 4px;
      }
      &-title{
        font-family: 'PingFang SC';
        font-size: 18px;
        font-weight: normal;
        line-height: 20px;
        letter-spacing: 0em;
        color: #343434;
        margin-right: 5px;
      }
    }
    &-content{
      width: 100%;
      margin-top: 20px;
      &-header{
        height: 80px;
        background-color: #EFFEF9;
        font-size: 16px;
      }
      & > li{
        display: flex;
        & > div{
          width: 33.33%;
        }
      }
    }
  }
  .data-item{
    height: 50px;
    &:nth-child(2n){
      background-color: #F9FBFC;
    }
  }
  .course-name{
    font-size: 14px;
  }
  .percentage{
    color:#F15F2D;
    margin: 0 10px;
  }
  .people{
    font-size: 12px;
    color: #9D95A9;
  }
  .difficulty-progress .el-progress--line {
    width: 100px;
  }
  .nodata{
    margin-top: 20px;
      text-indent: 15px;
      color: #888;
      width: 100%;
      height: 80px;
      line-height: 80px!important;
      border: 1px solid #e4e4e4;
      text-align: center;
      font-size: 17px;
      font-weight: 200!important;
      img{
        width: 114px;
        aspect-ratio: auto 114 / 30;
        height: 30px;
        vertical-align: middle !important;
      }
    }
</style>
  