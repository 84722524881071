<template>
  <div class="school-header">
    <div class="school-header-content">
      <div class="logo">
        <img v-if="data.logo" :src="data.logo" alt="">
      </div>
      <div class="info">
        <div class="info-name">
          {{ data.name }}
        </div>
        <div class="info-tag">
          <span v-if="data.belong">教育行政主管部门：{{ data.belong }}</span>
          <span v-if="data.tag">院校特性：{{ data.tag }}</span>
        </div>
        <div class="info-address">
          <!-- <font-awesome-icon :icon="['fas', 'map-marker-alt']" class="icon" style="font-size: 19px"/> -->
          <ul>
            <li>所在地：{{ data.province_name }}</li>
            <li>详细地址：{{ data.address }}</li>
          </ul>
        </div>
        <div class="info-link">
          <!-- <font-awesome-icon :icon="['fas', 'globe']" class="icon" style="font-size: 19px"/> -->
          <ul>
            <li>官方网址：{{ data.school_site }}</li>
            <li>招生网址：{{ data.site }}</li>
          </ul>
        </div>
        <div class="info-phone">
          <!-- <font-awesome-icon :icon="['fas', 'phone']" class="icon" style="font-size: 19px"/> -->
          <ul>
            <li>官方电话：{{ data.phone }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
  export default {
    name: 'SchoolHeader',
    props: {
      data:{
        type:Object
      }
    },
    setup(){
      
    }
  }
</script>
<style lang="scss" scoped>
  .school-header{
    width:100%;
    height: 262px;
    background-color: #FFE7DF;
    &-content{
      display: flex;
      width: 1180px;
      height: 100%;
      margin:0 auto;
    }
  }
  .logo{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 211px;
    height: 221px;
    border-radius: 10px;
    background-color: #fff;
    margin: auto 0;
    margin-right: 39px;

    & > img{
      width: 188px;
      height: 188px;
      background-color: #FFFF00;
    }
  }
  .info{
    margin-top: 34px;
    color: #c43302;
    font-size: 16px;
    &-name{
      font-size: 28.65px;
      line-height: 28.65px;
      letter-spacing: 0.009000000000000001em;
      margin-bottom: 18px;
    }
    &-tag{
      margin-bottom: 13px;
      line-height: 16px;
      & > span:nth-child(n+2){
        margin-left: 20px;
      }
    }
    &-address{
      @extend .info-tag;
      display: flex;
      & > ul{
        margin-left: 6px;
        line-height: 16px;

        & > li:first-child{
          margin-bottom: 13px;
        }
      }
    }
    &-link{
      display: flex;
      align-items: center;
      margin-bottom: 13px;
      & > ul{
        margin-left: 5px;
        display: flex;
        align-items: center;
        line-height: 16px;
        & > li:nth-child(n+2){
          margin-left: 20px;
        }
      }
    }
    &-phone{
      @extend .info-link;
    }
  }
</style>
  