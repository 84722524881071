<template>
  <div class="special-school">
    <div class="close">
      <div class="introduce-header">
        <p class="introduce-header-title">开设院校</p>
        <p class="introduce-header-tips">括号内为开设院校数量</p>
      </div>
      <ul class="introduce-content flex-wrap">
        <li :class="province_name === ''?'seleced':''" @click="seleced('')">全部({{condition.total}})</li>
        <li :class="province_name === item.province_name?'seleced':''" v-for="item in condition.list" @click="seleced(item.province_name)">{{item.province_name+'('+ item.count +')'}}</li>
      </ul>
    </div>
    <div class="school-list" v-if="dataList.length>0">
      <table class="professional_table">
        <tbody>
          <tr class="header">
            <th>院校名称</th>
            <th>办学类型</th>
            <th>办学性质</th>
            <th>院校类型</th>
            <th>操作</th>
          </tr>
          <tr class="body" v-for="(item,index) in dataList" :key="item.id">
            <td>{{ item.name }}</td>
            <td>{{ item.type_name }}</td>
            <td>{{ item.nature_name }}</td>
            <td>{{ item.subject_name }}</td>
            <td class="detail" @click="toDetail(item.id)">查看详情</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="nodata" v-else>
        <img src="@/assets/img/no_data.svg">
    </div>
    <div class="pagination">
          <el-pagination
            v-model:currentPage="currentPage"
            v-model:pageSize="pageSize"
            :small="small"
            :disabled="disabled"
            :background="background"
            prev-text="上一页"
            next-text="下一页"
            layout="prev, pager, next, jumper"
            :total="total"
            @current-change="handleCurrentPageChange"
          />
        </div>
  </div>
</template>
  
<script>
  import { specialSchoolCondition, getSpecialSchool } from '@/api/home';
import router from 'vue-router'; // 确保你正确导入了 router 实例

export default {
  name: 'SpecialSchool',
  props: {
    id: String
  },
  data() {
    return {
      condition: [],
      province_name: '',
      dataList: [],
      currentPage: 1,
      pageSize: 10,
      small: false,
      disabled: false,
      background: false,
      total: 0
    };
  },
  created() {
    this.fetchCondition();
    this.fetchSpecialSchool();
  },
  methods: {
    async fetchCondition() {
      try {
        const response = await specialSchoolCondition({ id: this.id });
        if (response.code === 1) {
          this.condition = response.data;
        }
      } catch (error) {
        // 处理错误
      }
    },
    async fetchSpecialSchool() {
      try {
        const response = await getSpecialSchool({
          id: this.id,
          province_name: this.province_name,
          page: this.currentPage,
          rows: this.pageSize
        });
        if (response.code === 1) {
          this.dataList = response.data.list;
          this.total = response.data.total;
        }
      } catch (error) {
        // 处理错误
      }
    },
    seleced(name) {
      this.province_name = name;
      this.currentPage = 1;
      this.fetchSpecialSchool();
    },
    handleTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // 可选的，使用平滑滚动效果
      });
    },
    handleCurrentPageChange(newPage) {
      this.currentPage = newPage;
      this.fetchSpecialSchool();
      this.handleTop();
    },
    toDetail(id) {
       // 构建带有查询参数的路由路径
       const baseUrl = this.$router.resolve({ name: 'schoolInfo' }).href;
      const queryParams = `?id=${encodeURIComponent(id)}`;
      const fullUrl = `${baseUrl}${queryParams}`;

      // 在新窗口打开带有查询参数的路径
      window.open(fullUrl, '_blank');
    }
  }
};
</script>
<style lang="scss" scoped>
  .special-school{
    padding-top: 20px;
  }
  .introduce{
    width: 100%;
    margin-top: 50px;
    &-header{
      display: flex;
      align-items: flex-end;
      border-bottom: 1px solid #DCDCDC;
      padding-bottom: 9px;
      &::before{
        content: '';
        background: #F15F2D;
        width: 3px;
        height: 20px;
        border-radius: 1.5px;
        margin: 0 4px;
      }
      &-title{
        font-family: 'PingFang SC';
        font-size: 18px;
        font-weight: normal;
        line-height: 20px;
        letter-spacing: 0em;
        color: #343434;
        margin-right: 5px;
      }
      &-tips{
        font-size: 12px;
        color: #A1A1A1;
      }
    }
    &-content{
      padding: 20px 14px;
      font-size: 14px;
      line-height: 30px;
      & > li{
        margin-right: 20px;
        padding: 0 5px;
        cursor: pointer;
        &:last-child{
          margin-right: 0;
        }
      }
    }
  }
  .flex-wrap{
    display: flex;
    flex-wrap: wrap;
  }
  .seleced{
    color: #fff;
    background-color: #F15F2D;
    border-radius: 5px;
  }
  .school-list{
    // width: 200px;
    // min-height: 700px;
    &-title{
      font-size: 14px;
      background-color: #EFFEF9;
      padding: 20px 0 20px 20px; 
    }
    & > ul{
      font-size: 14px;
      li{
        padding: 10px 0 10px 20px; 
        &:nth-child(2n){
          background-color: #F9FBFC;
        }
      } 
    }
  }
  .professional_table{
    table-layout: fixed;
    box-shadow: 0 0 13px #e6e6e6;
    border-radius: 10px;
    overflow: hidden;
    border-collapse: collapse;
    width: 100%;
      .header{
        background: #fafafa;
        height: 44px;
        & > th{
          font-size: 14px;
          text-align: center;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          vertical-align: middle;
          font-weight: 600;
          color: #000;
          border-right: 1px solid #ededed;
        }
      }
      .body{
        text-align: center;
        border: 1px solid #ededed;
        & > td{
          color: #666;
          padding: 15px 5px;
          vertical-align: middle;
          border-right: 1px solid #ededed;
          text-align: center;
          font-size: 14px;
        }
        .detail{
          color: #F15F2D;
          cursor: pointer;
        }
      }
  }
  .pagination{
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
  .nodata{
      text-indent: 15px;
      color: #888;
      width: 100%;
      height: 80px;
      line-height: 80px!important;
      border: 1px solid #e4e4e4;
      text-align: center;
      font-size: 17px;
      font-weight: 200!important;
      img{
        width: 114px;
        aspect-ratio: auto 114 / 30;
        height: 30px;
        vertical-align: middle !important;
      }
    }
</style>
  