<template>
  <div class="main">
    <div class="info">
      <div class="info-main">
        <div class="info-main-title">
          <h5>{{data.title}}</h5>
        </div>
        <div class="info-main-other">
          <span>{{data.createtime}}</span>
          <span v-if="data.source">来源：{{data.source}}</span>
        </div>
        <div class="info-main-content">
          <div v-html="data.content"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { getArticleDetail } from '@/api/home';

export default {
  components: {
  },
  data() {
    return {
      data: { title: '', updatetime: '', content: '' },
      id: null // 将会从路由中获取
    };
  },
  created() {
    this.id = this.$route.query.id; // 在 created 钩子中获取路由参数
    this.fetchArticleDetail(); // 调用方法获取文章详情
    this.handleTop(); // 滚动到页面顶部
  },
  methods: {
    async fetchArticleDetail() {
      try {
        const response = await getArticleDetail({ id: this.id });
        if (response.code === 1) {
          this.data = response.data;
        }
      } catch (error) {
        // 处理错误
      }
    },
    handleTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // 可选的，使用平滑滚动效果
      });
    }
  }
};
</script>
<!--声明语言，并且添加scoped-->
<style lang="scss" scoped>
  .main{
    width: 1180px;
    margin:29px auto;
    display: flex;
    justify-content: space-between;
  }
  .info{
    width: 100%;
    &-main{
      margin-top: 21.5px;
      &-title{
        h5{
          font-size: 30px;
          font-weight: normal;
          line-height: 30px;
          letter-spacing: 0em;
          color: #343434;
        }
      }
      &-other{
        border-bottom: 1px solid #DCDCDC;
        font-size: 16px;
        font-weight: normal;
        line-height: 16px;
        letter-spacing: 0em;
        color: #727272;
        margin-top: 14px;
        margin-bottom: 32.86px;
        padding-bottom: 7.58px;
        & > span:first-child{
          margin-right: 16px;
        }
      }
      &-content{
        width: 100%;
        font-family: PingFang-SC-Regular;
        font-size: 16px;
        font-weight: normal;
        & > div{
          letter-spacing: -0.018000000000000002em;
          color: #333333;
        }
      }
    }
  }
  .ad{
    width: 305px;
  }
</style>