<template>
	<div class="main">
	<div class="home_box">
		<div class="home_top">
			<div class="home_top_left">
				<img src="../../assets/logo.png" style="height: 40px" alt="" srcset="" />
			</div>
			<div v-if="!isLoggedIn" class="home_top_right" @click="changeTabs('index')">
				注册/登录
			</div>
			<div v-else class="home_top_right" @click="logout">
				退出登录
			</div>
		</div>
		
		<div class="home_main">
			<el-tabs v-model="activeName" @tab-click="handleClick">
					<el-tab-pane label="首页" name="index">
						<div class="A_main" v-if="!isLoggedIn || (isLoggedIn && !isVip) || (isLoggedIn && isVip && isInfoComplete)">
							<!-- 首页轮播图 -->
							<div class="home_top_swiper">
								<el-carousel trigger="click" height="100%" :interval="3000" class="el-car" >
									<el-carousel-item>
										<img src="../../assets/banner.jpg" alt="" class="img" />
									</el-carousel-item>
								</el-carousel>
							</div>
							<!-- 登录 -->
							<div class="A_form" v-if="!isLoggedIn">
								<div class="A_form_ul">
									<div class="A_form_ul_lia">登录</div>
									<div class="A_form_ul_lib">新用户登录后将自动注册</div>
									<div class="A_form_ul_lic">
										<div class="A_form_ul_lic_a">
											<el-input  placeholder="手机号" maxlength="11" v-model="mobile" @input="setMobile"></el-input>
										</div>
										<div class="A_form_ul_lic_a" style="width: 100%;">
											<el-input class="code-class" v-model="code" maxlength="5" placeholder="验证码" @input="setCode"></el-input>
											<el-button  v-show="show" class="btn" type="danger" plain @click="getCode">获取验证码</el-button>
											<el-button  v-show="!show" class="btn" type="danger" plain>{{count}}</el-button>
											
										</div>
									</div>
									<div class="A_form_ul_lid">
										<div class="A_form_ul_lid_a" @click="agreement = !agreement">
											<div :class="['quan',agreement?'agreement':'']">
											</div>
											<div class="quanright">
												已阅读并同意<span>《相关条款》</span>
											</div>
										</div>
									</div>
									
									<div class="A_form_ul_lie">
										<div class="A_form_ul_liea" @click="login">
											验证登录
										</div>							
									</div>
								</div>
							</div>
							
							<!-- 激活系统 -->
							<div class="A_form" v-if="isLoggedIn && !isVip">
								<div class="A_form_ul">
									<div class="A_form_ul_lia">激活系统</div>
									<div class="A_form_ul_lib">输入卡号密码激活系统</div>
									<div class="A_form_ul_lic">
										<div class="A_form_ul_lic_a">
											<el-input v-model="no" maxlength="16" placeholder="卡号" @input="setNo"></el-input>
										</div>
										<div class="A_form_ul_lic_a">
											<el-input v-model="password" maxlength="12" placeholder="密码" @input="setPassword"></el-input>
										</div>
									</div>
									<div class="A_form_ul_lie A_form_ul_lieJh">
										<!-- <div class="A_form_ul_liea">
											购买
										</div> -->
										<el-tooltip class="item" effect="dark" placement="bottom">
											<div class="A_form_ul_liea">
												购买
											</div>
											<div slot="content" class="tooltip-content">
												<img src="https://data2.palou.fun//uploads/20240419/6f397afe112a92ef1d13bb3090ad738a.png" alt="" style="max-width: 300px; max-height: 300px;">
											</div>
										</el-tooltip>

										<div class="A_form_ul_liea" @click="activation">
											激活
										</div>	
									</div>
								</div>
							</div>
							<!-- 激活系统 -->
							<div class="A_form" v-if="isLoggedIn && isVip && isInfoComplete">
								<div class="A_form_uls">
									<div class="A_form_ul_title">2024模拟填报</div>
									<div class="A_form_ul_update">
										<div class="info-score">{{ userInfo.cultural_score?userInfo.cultural_score:'' }}</div>
										<div class="info-update" @click="changeTabs('C')"><span>修改</span><img src="../../assets/img/bi.png" alt="" /></div>
									</div>
									<div class="A_form_ul_button">
										<div class="" @click="changeTabs('A')">
											<img src="../../assets/img/icon.png" alt="" />智能填报
										</div>	
									</div>
								</div>
							</div>
						</div>
						<!-- 确定激活后 展示激活系统说明 -->
						<div class="jhtext" v-if="vipSuccess">
									<div class="jhtext1">激活系统</div>
									<div class="jhtext2">到期时间：{{ user && user.formatted_vip_expirationtime?user.formatted_vip_expirationtime:''}}</div>
									<div class="jhtext2">
										<div>适用范围：</div>
										<div>
											<div class="category-data" v-for="(item,index) in category_data" :key="index">
												{{item.name}}:
												<span v-for="(t,i) in item.son" :key="i">{{t.name}}</span>
											</div>
										</div>
										
									</div>
									<div class="jhtextbtn" @click="vipSuccess = false"> 确定 </div>
							</div>
							
						<!-- 设置成绩 样式在index.css-->
							<div class="setting" v-if="isLoggedIn && isVip && !isInfoComplete && !vipSuccess">
									<div class="setting1">
										<div class="setting1div">
											设置成绩
											<!-- <span class="span1">(*为非必填)</span> -->
										</div>
										<div class="setting2div">
											剩余可修改次数:
											<span class="span2">{{user && user.modify_num?user.modify_num:''}}</span>
										</div>
									</div>
									
									
									<div class="">
										<el-form ref="form" :model="userForm" label-width="80px">
											<el-row :gutter="21">
												<el-col :span="12">
													<el-form-item label="姓名">
													<el-input v-model="userForm.name" maxlength="5" @input="setName"></el-input>
													</el-form-item>
												</el-col>
												<el-col :span="12">
													<el-form-item label="学校班级">
													<el-input v-model="userForm.school_class" maxlength="16" @input="setClass"></el-input>
													</el-form-item>
												</el-col>
												
												<el-col :span="12">
													<el-form-item label="生源地">
													<el-select v-model="province_index" placeholder="请选择" @change="setProvince">
														<el-option v-for="(item,index) in categorydata" :key="item.id" :label="item.name" :value="index"></el-option>
													</el-select>
													</el-form-item>
												</el-col>
												<el-col :span="12">
													<el-form-item label="考生类型">
													<el-select :disabled="province_index === null" v-model="candidate_index" placeholder="请选择" @change="setCandidate">
														<el-option v-for="(item,index) in candidatedata" :key="item.id" :label="item.name" :value="index"></el-option>
													</el-select>
													</el-form-item>
												</el-col>
											</el-row>
										<el-row :gutter="21" v-if="subject_model == 1">
											<el-col :span="21">
												<el-form-item label="选科">
													<el-checkbox-group 
														v-model="subject"
														:max="3">
														<el-checkbox v-for="item in subjectdata1" :label="item.id"  :key="item.id">{{item.name}}</el-checkbox>
													</el-checkbox-group>
												</el-form-item>
											</el-col>
										</el-row>
										<el-row :gutter="21" v-if="subject_model == 2">
											<el-col :span="21">
												<el-form-item label="选科-首选">
													<el-checkbox-group 
														v-model="subject1"
														:max="1" @change="merge_subject">
														<el-checkbox v-for="item in subjectdata2_1" :label="item.id"  :key="item.id" >{{item.name}}</el-checkbox>
													</el-checkbox-group>
												</el-form-item>
												
											</el-col>
											<el-col :span="21" v-if="subject_model == 2">
												<el-form-item label="选科-再选">
													<el-checkbox-group 
														v-model="subject2"
														:max="2" @change="merge_subject">
														<el-checkbox v-for="item in subjectdata2_2" :label="item.id" :key="item.id" >{{item.name}}</el-checkbox>
													</el-checkbox-group>
												</el-form-item>
											</el-col>
										</el-row>
										<el-row :gutter="21" v-if="subject_model == 3">
											<el-col :span="21">
												<el-form-item label="选科">
													<el-checkbox-group 
														v-model="subject"
														:max="1">
														<el-checkbox v-for="item in subjectdata3" :label="item.id" :key="item.id">{{item.name}}</el-checkbox>
													</el-checkbox-group>
												</el-form-item>
											</el-col>
										</el-row>
										<el-row :gutter="21" v-if="subject_model == 4">
											<el-col :span="21">
												<el-form-item label="选科">
													<el-checkbox-group 
														v-model="subject"
														:max="3">
														<el-checkbox v-for="item in subjectdata4" :label="item.id" :key="item.id">{{item.name}}</el-checkbox>
													</el-checkbox-group>
												</el-form-item>
											</el-col>
										</el-row>
										
										
										<el-row :gutter="21">
											<el-col :span="12" v-if="score_model.includes('1')">
												<el-form-item label="高考分数">
												<el-input v-model="userForm.cultural_score" maxlength="6" @input="setCulturalScore"></el-input>
												<div class="deng"><img src="../../assets/img/deng.png"/>可填写预测分数</div>
												</el-form-item>
											</el-col>
											<el-col :span="12" v-if="score_model.includes('4')">
												<el-form-item label="位次">
												<el-input v-model="userForm.rank" maxlength="10" @input="setRank"></el-input>
												</el-form-item>
											</el-col>
											<el-col :span="12" v-if="score_model.includes('2')">
												<el-form-item label="艺考分数">
												<el-input v-model="userForm.art_score" maxlength="6" @input="setArtScore"></el-input>
												</el-form-item>
											</el-col>
										</el-row>
										</el-form>
									</div>
									<div class="jhtextbtn" @click="inspectSubject"> 保存 </div>
							</div>
							<div class="func">
								<div @click="filling()">
									<img src="../../assets/img/tian.png" alt="">
									<span>模拟填报</span>
								</div>
								<div @click="jumpTo('schoolList')">
									<img src="../../assets/img/cha.png" alt="">
									<span>查大学</span>
								</div>
								<div @click="jumpTo('specialList')">
									<img src="../../assets/img/zhao.png" alt="">
									<span>找专业</span>
								</div>
								<div @click="jumpTo('articleList')">
									<img src="../../assets/img/sheng.png" alt="">
									<span>升学资讯</span>
								</div>
								<div @click="jumpTo('test')">
									<img src="../../assets/img/xing.png" alt="">
									<span>性格测试</span>
								</div>
							</div>
							<div class="article-ad1">
								<div class="hot-arcitle">
									<div class="hot-arcitle-header">
										<img src="../../assets/img/hot.png" alt="">
										<span>热门资讯</span>
										<div style="cursor: pointer;" @click="jumpTo('articleList')">更多</div>
									</div>
									<div class="hot-arcitle-list">
										<template v-for="(item,index) in article_list">
											<div class="arcitle-item" v-if="index < 4" @click="goToArticleDetail(item.id)">
												<div>
													<p class="arcitle-item-title">{{ item.title }}</p>
													<p class="arcitle-item-other">
														<span>{{ item.creattime }}</span>
														<span>{{ item.num }}阅读</span>
													</p>
												</div>
												<img :src="item.image" alt="">
											</div>
										</template>
									</div>
									<div class="hot-arcitle-more" style="cursor: pointer;" @click="jumpTo('articleList')">更多</div>
								</div>
								<div class="ad1-list">
									<template v-for="(item,index) in ad.indexRight">
										<a :href="item.url" target="_blank">
											<img class="ad1-image" v-if="index < 2" :src="item.img" alt="">
										</a>
									</template>
								</div>
							</div>
							<div class="xian"></div>
							<div class="ad2">
								<template v-for="(item,index) in ad.indexBottom">
									<a :href="item.url" target="_blank">
										<img class="ad2-image" v-if="index < 4" :src="item.img" alt="">
									</a>
								</template>
							</div>
					</el-tab-pane>
				<el-tab-pane label="模拟填报" name="A" :disabled="!isLoggedIn || !isVip || !isInfoComplete">
					<div class="A_main_box" v-if="isLoggedIn && isVip && isInfoComplete">
						<!-- 都设置好后展示的内容 -->
						<div class="alllist">
							<div class="alllistul">
								<div class="alllistul-l">
									<div>
										<span>{{userInfo.province?userInfo.province:''}}</span>
										<span>{{userInfo.candidate_type?userInfo.candidate_type:''}}</span>
										<span>{{userInfo?userInfo.subject_name_str:''}}</span>
										<span>{{userInfo.cultural_score?userInfo.cultural_score:''}}</span>
									</div>
								</div>
								<div class="alllistul-r" @click="changeTabs('C')"><span>修改</span><img src="../../assets/img/bi.png" alt="" /></div>
							</div>
							<div class="alllistul">
								<div class="alllistul-l">
									<div>志愿表({{volunteer_num}}) </div>
									<div class="font-14" style="color: rgba(0, 0, 0, 0.6);">数据更新后自动新建志愿表</div>
								</div>
								<div class="alllistul-r" @click="changeTabs('B')"><span class="span1">全部</span><img src="../../assets/img/all.png" alt="" /></div>
							</div>
							
							<!-- <div class="alllistulNew_c">	
									<div ref="echartsContainer" style="width: 600px; height: 360px;"></div>
								</div> -->
							<div class="alllistulNew" v-for="(item,index) in batch_list" :key="index" v-if="(item.defend + item.sprint + item.steady) > 0">
								<div class="alllistulNew_t">
									<span class="span1">{{item.name}}</span>
									<template v-if="item.cultural_line > 0 || item.art_line > 0">
										<span class="span2">批次线:{{item.cultural_line}}/{{item.art_line}}(艺)</span>
										<span v-if="(userInfo.cultural_score >= item.cultural_line) && (userInfo.art_score >= item.art_line)" class="span4">过线</span>
										<span v-else class="span3">未过线</span>
									</template>
									<span class="span2">数据更新至{{item.year}}</span>
								</div>
								<div class="alllistulNew_c">	
									<div :id="'echartsContainer' +index"  style="width: 600px; height: 360px;"></div>
								</div>
								<div class="alllistulNew_b">
									<div class="A_form_ul_lie A_form_ul_lieJh">
										<div class="A_form_ul_liea" @click="toAi(item.id,item.data_model,item.name,item.year)">
											一键填报
										</div>		
										<div class="A_form_ul_liea" @click="toDataList(item.id,item.data_model,item.name,item.year)">
											智能填报
										</div>	
									</div>
								</div>
							</div>
						</div>	
						<div class="A_main_notice">
							<div class="A_main_notice_tit">填报须知</div>
							<ul>
								<li> 1、当前模拟填报数据以省考试院2023年招生计划为依据，结合教育部发布的选科要求，针对部分专业将选科从“物理”变更为“物理+化学”综合推荐。本系统依据历年院校录取数据提供高考志愿填报智能模拟功能，不等同于实际填报高考志愿，建议考生在填报志愿时，结合本系统的推荐以完善志愿表，正式填报请登录省考试院指定填报网站.</li>
								<li> 2、当前2024年招生计划为模拟数据，仅供模拟参考，考试院将于2024年6月发布招生计划，届时本系统将同步更新上线。 </li>
								<li>3、2012-2023年各院校录取数据，参考各省市出版的填报指南以及各本专科院校的官网历年录取数据.</li>
								<li>4、您正在使用的是山东版，提供“普通类1段 2段、”2个批次的志愿智能模拟功能，您可以按系统的重点填报提示选择适合自己的批次模拟，也可直接选择自己关注的批次模拟.</li>
								<li>5、在正式填报时，以教育考试院公布的最新招生计划为准； 6、若推荐院校招生计划数出现新增专业，会提示”新增专业“且历三年的录取数据默认采用该院校最低录取分数线展示。</li>
							</ul>
						</div>
					</div>
				</el-tab-pane>
				<!-- <el-tab-pane label="院校库" name="school" >

				</el-tab-pane>
				<el-tab-pane label="专业库" name="special" >

				</el-tab-pane>
				<el-tab-pane label="升学资讯" name="article" >
					
				</el-tab-pane> -->
				<el-tab-pane label="志愿表" name="B" :disabled="!isLoggedIn || !isVip || !isInfoComplete">
					<div class="zhiyuanbox">
						<div class="zhiyuanlist" v-for="item in userVolunteerList">
							<div class="zhiyuanlist_l">
								<div class="font-28">{{item.name}}</div>
								<div class="font-20 zhiyuanlist_l_1">
									<div>{{item.province}}丨{{item.candidate_type}}丨{{item.optional_str}}</div>
									<div>{{item.year}} {{item.batch_name}}</div>
								</div>
							</div>
							<div class="zhiyuanlist_r">
								<el-button type="danger" plain @click="toVolunteer(item.id,item.type)">查看</el-button>
								<div class="zhiyuanlist_r_de"@click="deleteVlounteer(item.name,item.id)">
									<img src="../../../src/assets/img/del.png" alt="" />删除
								</div>
							</div>
						</div>
					</div>
				</el-tab-pane>
				<el-tab-pane label="设置成绩" name="C" :disabled="!isLoggedIn || !isVip || !isInfoComplete">
						<div class="setting">
							<div class="setting1">
								<div class="setting1div">
									设置成绩
									<!-- <span class="span1">(*为非必填)</span> -->
								</div>
								<div class="setting2div">
									剩余可修改次数:
									<span class="span2">{{user && user.modify_num?user.modify_num:''}}</span>
								</div>
							</div>
								
								
							<div class="">
								<el-form ref="form" :model="userForm" label-width="80px">
									<el-row :gutter="21">
										<el-col :span="12">
											<el-form-item label="姓名">
												<el-input v-model="userForm.name" maxlength="5" @input="setName"></el-input>
											</el-form-item>
										</el-col>
										<el-col :span="12">
											<el-form-item label="学校班级">
												<el-input v-model="userForm.school_class" maxlength="16" @input="setClass"></el-input>
											</el-form-item>
										</el-col>
										
										<el-col :span="12">
											<el-form-item label="生源地">
												<el-select v-model="province_index" placeholder="请选择" @change="setProvince">
												<el-option v-for="(item,index) in categorydata" :key="item.id" :label="item.name" :value="index"></el-option>
												</el-select>
											</el-form-item>
										</el-col>
										<el-col :span="12">
											<el-form-item label="考生类型">
												<el-select :disabled="province_index === null" v-model="candidate_index" placeholder="请选择" @change="setCandidate">
												<el-option v-for="(item,index) in candidatedata" :key="item.id" :label="item.name" :value="index"></el-option>
												</el-select>
											</el-form-item>
										</el-col>
									</el-row>
								<el-row :gutter="21" v-if="subject_model == 1">
									<el-col :span="21">
										<el-form-item label="选科">
											<el-checkbox-group 
												v-model="subject"
												:max="3">
												<el-checkbox v-for="item in subjectdata1" :label="item.id" :key="item.id">{{item.name}}</el-checkbox>
												</el-checkbox-group>
										</el-form-item>
									</el-col>
									</el-row>
									<el-row :gutter="21" v-if="subject_model == 2">
									<el-col :span="21">
										<el-form-item label="选科-首选">
											<el-checkbox-group 
												v-model="subject1"
												:max="1" @change="merge_subject">
												<el-checkbox v-for="item in subjectdata2_1" :label="item.id" :key="item.id" >{{item.name}}</el-checkbox>
												</el-checkbox-group>
										</el-form-item>
										
									</el-col>
									<el-col :span="21" v-if="subject_model == 2">
										<el-form-item label="选科-再选">
											<el-checkbox-group 
												v-model="subject2"
												:max="2" @change="merge_subject">
												<el-checkbox v-for="item in subjectdata2_2" :label="item.id" :key="item.id" >{{item.name}}</el-checkbox>
												</el-checkbox-group>
										</el-form-item>
									</el-col>
									</el-row>
									<el-row :gutter="21" v-if="subject_model == 3">
									<el-col :span="21">
										<el-form-item label="选科">
											<el-checkbox-group 
												v-model="subject"
												:max="1">
												<el-checkbox v-for="item in subjectdata3" :label="item.id" :key="item.id">{{item.name}}</el-checkbox>
												</el-checkbox-group>
										</el-form-item>
									</el-col>
									</el-row>
									<el-row :gutter="21" v-if="subject_model == 4">
									<el-col :span="21">
										<el-form-item label="选科">
											<el-checkbox-group 
												v-model="subject"
												:max="3">
												<el-checkbox v-for="item in subjectdata4" :label="item.id" :key="item.id">{{item.name}}</el-checkbox>
												</el-checkbox-group>
										</el-form-item>
									</el-col>
									</el-row>
								
									
									<el-row :gutter="21">
									<el-col :span="12" v-if="score_model.includes('1')">
										<el-form-item label="高考分数">
											<el-input v-model="userForm.cultural_score" maxlength="6" @input="setCulturalScore"></el-input>
											<div class="deng"><img src="../../assets/img/deng.png"/>可填写预测分数</div>
										</el-form-item>
									</el-col>
										<el-col :span="12" v-if="score_model.includes('4')">
										<el-form-item label="位次">
											<el-input v-model="userForm.rank" maxlength="10" @input="setRank"></el-input>
										</el-form-item>
									</el-col>
									<el-col :span="12" v-if="score_model.includes('2')">
										<el-form-item label="艺考分数">
											<el-input v-model="userForm.art_score" maxlength="6" @input="setArtScore"></el-input>
										</el-form-item>
									</el-col>
									</el-row>
								</el-form>
							</div>
							<div class="jhtextbtn" @click="inspectSubject"> 保存 </div>
						</div>
				</el-tab-pane>
				<!-- <el-tab-pane label="手机版" name="D">手机版</el-tab-pane> -->
				</el-tabs>
		</div>
		
		
		
		
	

	</div>
	<Foot></Foot>
	</div>
</template>

<script>
	import router from '@/router' // 引入Vue Router实例
	import * as echarts from 'echarts';
	import Foot from '@/components/Footer.vue';
	// import Chart from 'chart.js/auto';
	// import Swiper from "swiper";
	// import "swiper/css/swiper.min.css
	// import  {group} from '@/utils/fenlei'
	// import BaiduMap from "@/view/home/component/map.vue";
	import { mapGetters } from 'vuex';
	import { storage } from '@/utils/storage';
	import {
		sendSms,
		login,
		activation,
		getCardInfo,
		updateUserInfo,
		getBatch,
		getUserVolunteerList,
		deleteUserVolunteer,
		hotArticle,
		getAd
	} from "@/api/home";
export default {
  components: {
    // BaiduMap,
	Foot,
  },

  data() {
    return {
		ad: {
			"indexBottom":[],
			"indexRight":[],
		},
		article_list:[],

		user: null,
		/* 注册登录 */
		mobile:'',
		code:'',
		count:'',
		show: true,
		timer: null,
		agreement: false, // 同意协议
		activeName: 'index',
		/* 激活vip */
		no:'',
		password:'',
		vipSuccess: false,
		/* 激活成功展示信息 */
		category_data: null,
		/* 提前获取的用户可填写信息 */
		categorydata: [],
		province_index: null,
		
		candidatedata: [],
		candidate_index: null,
		
		subject_model: null,
		subjectdata1: [
			{
				"id":1,
				"name":'物理'
			},
			{
				"id":2,
				"name":'历史'
			},
			{
				"id":3,
				"name":'化学'
			},
			{
				"id":4,
				"name":'生物'
			},
			{
				"id":5,
				"name":'政治'
			},
			{
				"id":6,
				"name":'地理'
			}
		],
		subjectdata2_1: [
			{
				"id":1,
				"name":'物理'
			},
			{
				"id":2,
				"name":'历史'
			}
		],
		subjectdata2_2: [
			{
				"id":3,
				"name":'化学'
			},
			{
				"id":4,
				"name":'生物'
			},
			{
				"id":5,
				"name":'政治'
			},
			{
				"id":6,
				"name":'地理'
			}
		],
		subjectdata3: [
			{"name":'理科',"id":8},
			{"name":'文科',"id":9},
		],
		subjectdata4: [
			{
				"id":1,
				"name":'物理'
			},
			{
				"id":2,
				"name":'历史'
			},
			{
				"id":3,
				"name":'化学'
			},
			{
				"id":4,
				"name":'生物'
			},
			{
				"id":5,
				"name":'政治'
			},
			{
				"id":6,
				"name":'地理'
			},
			{
				"id":7,
				"name":'技术'
			}
		],
		/* 首选再选模式 */
		subject1:[],
		subject2:[],
		/* 其他模式 */
		subject:[],
		subject_name_str: '',
		score_model:[],
		/* 用户填写信息,用户修改信息 */
		userForm:{
			"name": "",
			"school_class": "",
			"province": "",
			"candidate": "",
			"subject_str": '',
			"cultural_score": "",
			"rank": "",
			"art_score": "",
		},
		/* 批次列表 */
		batch_list: [],

		xuankeData:['物理', '化学', '生物', '历史','地理'],

		form: {
			name: '',
			region: '',
			date1: '',
			date2: '',
			delivery: false,
			type: [],
			resource: '',
			desc: ''
		},

		userVolunteerList:[],
		volunteer_num: 0,
    };
  },
  computed: {
	...mapGetters('user',['isLoggedIn', 'userInfo' , 'isVip', 'isInfoComplete']),
	 // 创建一个计算属性来格式化时间戳
	
  },
  created() {
	this.mobile = this.$route.query.mobile;
    this.code = this.$route.query.code;
	if(this.$route.query.mobile && this.$route.query.code){
		this.logout();
		this.agreement = true;
		this.login();
	}
	if(this.$route.query.tab){
		this.changeTabs(this.$route.query.tab);
	}
	this.hotArticle();
	this.getAd();
  },
  mounted() {
	// window.addEventListener('popstate', this.onPopState);
	// 添加visibilitychange事件监听器
    this.visibilityChangeListener = () => {
      if (document.visibilityState === 'visible') {
        this.refresh(); // 调用你的刷新方法
      }
    };
    document.addEventListener('visibilitychange', this.visibilityChangeListener);
	this.user = storage.get('userInfo') ?? null;
	this.$nextTick(() => {
		this.cardInfo();
		if (this.isLoggedIn && this.isVip && this.isInfoComplete) {
			this.getBatch();
		}
	});

  },
  watch: { 
		'$route' (to, from) {
			if(to.query.tab){
				this.changeTabs(to.query.tab);
			}
		}
	},
  beforeDestroy() {
    window.removeEventListener('popstate', this.onPopState);
	// 移除visibilitychange事件监听器
	document.removeEventListener('visibilitychange', this.visibilityChangeListener);
  },
  methods: {
	refresh(){
		// console.log('刷新了')
		window.location.reload();
	},
	onPopState(event) {
		// 检查是否是从其他页面返回
		this.user = storage.get('userInfo') ?? null;
			this.cardInfo();
		if (this.isLoggedIn && this.isVip && this.isInfoComplete) {
			this.getBatch();
		}
    },
	formattedVipExpirationtime(timestamp) {
		const date = new Date(timestamp*1000);
		const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
		return formattedDate;
    },
	async hotArticle(){
		try {
			const res = await hotArticle();
			if (res.code === 1) {
				let data = res.data;
				this.article_list = data;
			} else {
				this.$message.error(res.msg);
			}
		} catch (error) {
			this.$message.error('请求失败');
		}
	},
	async getAd(){
		try {
			const res = await getAd();
			if (res.code === 1) {
				let data = res.data;
				this.ad = data;
			} else {
				this.$message.error(res.msg);
			}
		} catch (error) {
			this.$message.error('请求失败');
		}
	},
	goToArticleDetail(id) {
      // 注意：在 Vue 2 中，通常使用 query 而不是 params 来传递路由参数
      // 但如果你确实需要 params（例如，在命名视图或星号路由中），你可能需要不同的处理方式
      // 这里假设你使用的是 query
      const baseUrl = this.$router.resolve({ name: 'articleDetail' }).href;
      const queryParams = `?id=${encodeURIComponent(id)}`;
      const fullUrl = `${baseUrl}${queryParams}`;
      // 在新窗口打开带有查询参数的路径
      window.open(fullUrl, '_blank');
    },
	//登录注册
	// 获取code
	getCode(){
		let that = this;
		
		if (that.mobile == '') {
			that.$message('请输入手机号');
			return
		} else if (that.mobile.length < 11) {
			that.$message('手机号格式不正确');
			return
		} else {
			sendSms({
				mobile: that.mobile
			})
			.then(res => {
				if (res.code == 1) {
					const TIME_COUNT = 60;
					if (!that.timer) {
						that.count = TIME_COUNT;
						that.show = false;
						that.state = false;
						that.timer = setInterval(() => {
							if (that.count > 0 && that.count <= TIME_COUNT) {
								that.count--;
							} else {
								that.show = true;
								that.state = false;
								clearInterval(that.timer);
								that.timer = null;
							}
						}, 1000)
					}
					that.$message({
						message: '验证码已发送',
						type: 'success'
					});
				}else{
					that.$message.error(res.msg);
				}
			})
			.catch(error => {
				console.log(error);
				// 处理错误
				that.$message.error('请求失败');
			});
		}
	},
	setMobile(e){
		this.mobile = e;
	},
	setCode(e){
		this.code = e;
	},
	logout(){
		this.$store.dispatch('user/logout');
		this.$router.push('/');
	},
	async login() {
		// 提前返回校验不通过的情况
		if (!this.mobile || !this.code) {
			return this.$message.error('请输入手机号和验证码');
		}
		if (!this.agreement) {
			return this.$message.error('请阅读并同意隐私协议');
		}

		try {
			const res = await login({
				"mobile": this.mobile,
				"code": this.code
			});

			if (res.code === 1) {
				const data = res.data;
				const subject = data.optional;

				// 提取科目数字到名称的映射
				const subjectMap = {
					1: '物理',
					2: '历史',
					3: '化学',
					4: '生物',
					5: '政治',
					6: '地理',
					7: '技术',
					8: '理科',
					9: '文科'
				};

				// 使用map转换科目数字为名称
				if (subject) {
					data.subject_name_str = subject.split(',').map(s => subjectMap[parseInt(s, 10)]).join(',');
				}

				storage.set('userInfo', data);
				storage.set('token', data.token);
				this.$store.dispatch('user/login', { userInfo: data, token: data.token });
				this.$message.success('登录成功');
				this.cardInfo();
				if(this.isLoggedIn && this.isVip && this.isInfoComplete){
					this.getBatch();
				}else{
				}
			} else {
				this.$message.error(res.msg);
			}
		} catch (error) {
			console.log(error);
			this.$message.error('请求失败');
		}
	},
	//切换tab
	handleClick(tab, event) {
		if(tab.name === 'B'){
			this.getUserVolunteerList();
		}
		if(tab.name === 'C'){
			this.handleUserForm();
		}
	//   console.log(tab.name);
	//   console.log(event);
	},
	changeTabs(name){
		this.activeName = name;
		if(name === 'B'){
			this.getUserVolunteerList();
		}
		if(name === 'C'){
			this.handleUserForm();
		}
	},
	handleUserForm(){
		const userInfo = storage.get('userInfo');
		this.user = userInfo;
		this.userForm.name = userInfo.nickname;
		this.userForm.school_class = userInfo.class;
		let provinceIndex = this.categorydata.findIndex(item => item.name === userInfo.province);
		if (provinceIndex !== -1) {
			this.province_index = provinceIndex;
			this.setProvince(provinceIndex);

			let candidateIndex = this.candidatedata.findIndex(value => value.name === userInfo.candidate_type);
			if (candidateIndex !== -1) {
				this.candidate_index = candidateIndex;
				this.setCandidate(candidateIndex);
			}
		}
		let optional = userInfo.optional;
		let optional_arr = optional.split(",");
		// 转换为数字数组
		optional_arr = optional_arr.map(Number);
		if(this.subject_model == 2){
			
			// 遍历 optional_arr，将 1 或 2 添加到 this.subject1，并从 optional_arr 中删除
			for (let i = optional_arr.length - 1; i >= 0; i--) {
				if (optional_arr[i] === 1 || optional_arr[i] === 2) {
					this.subject1.push(optional_arr[i]);
					optional_arr.splice(i, 1); // 删除当前元素
				}
			}

			// 将剩余的 optional_arr 赋值给 this.subject2
			this.subject2 = optional_arr;
			this.merge_subject();
		}else{
			this.subject = optional_arr;
		}
		this.userForm.cultural_score = this.score_model.includes('1')?userInfo.cultural_score:0;
		this.userForm.rank = this.score_model.includes('4')?userInfo.rank:0;
		this.userForm.art_score = this.score_model.includes('2')?userInfo.art_score:0;
	},
	async getUserVolunteerList(){
		try {
			const res = await getUserVolunteerList({
				page:1,
				rows:1000
			});

			if (res.code === 1) {
				let data = res.data;
				this.userVolunteerList = data.list;
			} else {
				this.$message.error(res.msg);
			}
		} catch (error) {
			this.$message.error('请求失败');
		}
	},
	deleteVlounteer(name,id){
		this.$confirm('是否删除'+name+'?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
			this.deleteUserVolunteer(id);
        }).catch(() => {
                 
        });
		
	},
	async deleteUserVolunteer(id){

		try {
			const res = await deleteUserVolunteer({
				id:id
			});
			if (res.code === 1) {
				this.$message({
					type: 'success',
					message: '删除成功!'
				});
				this.getUserVolunteerList();
			} else {
				this.$message.error(res.msg);
			}
		} catch (error) {
			this.$message.error('请求失败');
		}
	},
	//激活vip
	setNo(e){
		this.no = e;
	},
	setPassword(e){
		this.password = e;
	},
	async activation(){
		if (!this.no) {
			this.$message.error('请输入卡号');
			return;
		}
		if (!this.password) {
			this.$message.error('请输入密码');
			return;
		}
		try {
			const res = await activation({
				"no":this.no,
				"password":this.password
			});

			if (res.code === 1) {
				// 假设res.data是VIP过期时间
				const vipExpirationTime = res.data;
				const userInfo = storage.get('userInfo');
				if (userInfo) {
					// 更新用户信息中的VIP过期时间
					userInfo.vip_expirationtime = vipExpirationTime;
					userInfo.formatted_vip_expirationtime = this.formattedVipExpirationtime(vipExpirationTime);
					// 存储更新后的用户信息
					storage.set('userInfo', userInfo);
					// 调用Vuex的action来更新组件内的用户信息状态
					this.$store.dispatch('user/updateUserInfo', userInfo);
					this.user = userInfo;
					this.vipSuccess = true;
					this.cardInfo();
				} else {
					// 如果userInfo不存在，可能需要处理错误或重定向到登录页面等
					this.$store.dispatch('user/logout');
					this.$message.error('用户信息不存在，请重新登录');
				}
			} else {
				this.$message.error(res.msg);
			}
		} catch (error) {
			this.$message.error('请求失败');
		}
	},
	async cardInfo(){
		try {
			const res = await getCardInfo();

			if (res.code === 1) {
				let data = res.data;
				this.category_data = data;
				data.forEach((value,index)=>{
					if(value.name === this.province){
						this.candidatedata = [value.son];
						value['son'].forEach((v,i)=>{
							if(v.name === this.candidate){
								this.subject_model = v.subject_model;
								this.score_model = v.score_model;
							}
						});
					}
				});
				this.categorydata = data;
			} else {
				this.$message.error(res.msg);
			}
		} catch (error) {
			// this.$message.error('请求失败');
		}
	},
	setName(e){
		this.userForm.name = e;
	},
	setClass(e){
		this.userForm.school_class = e;
	},
	setProvince(e){
		let data = this.categorydata[e];
		this.userForm.province = data.name;
		this.candidatedata = data.son;
		this.userForm.candidate = '';
		this.candidate_index = null;
		this.subject_model = null;
		this.score_model = [];
		this.subject = [];
		this.subject1 = [];
		this.subject2 = [];
	},
	setCandidate(e){
		let data = this.candidatedata[e];
		this.subject_model = data.subject_model;
		this.score_model = data.score_model;
		this.userForm.candidate = data.name;
		this.subject = [];
		this.subject1 = [];
		this.subject2 = [];
	},
	merge_subject(){
		this.subject = this.subject1.concat(this.subject2);
	},
	setCulturalScore(e){
		this.userForm.cultural_score = e;
	},
	setRank(e){
		this.userForm.rank = e;
	},
	setArtScore(e){
		this.userForm.art_score = e;
	},
	inspectSubject(){
		if((this.subject_model == 1 || this.subject_model == 2) && this.subject.length != 3){
			this.$message.error('请选择三门学科');
			return
		}
		if(this.subject_model == 3 && this.subject.length != 1){
			this.$message.error('请选择学科');
			return
		}
		let subject_str = this.subject;
		let subject_name_str = [];
		this.subject.forEach((value,index)=>{
			switch(value){
				case 1:
					subject_name_str.push('物理');
					break;
				case 2:
					subject_name_str.push('历史');
					break;
				case 3:
					subject_name_str.push('化学');
					break;
				case 4:
					subject_name_str.push('生物');
					break;
				case 5:
					subject_name_str.push('政治');
					break;
				case 6:
					subject_name_str.push('地理');
					break;
				case 7:
					subject_name_str.push('技术');
					break;
				case 8:
					subject_name_str.push('理科');
					break;
				case 9:
					subject_name_str.push('文科');
					break;
			}
		});
			
		this.userForm.subject_str = subject_str.toString();
		this.subject_name_str = subject_name_str.toString();
		this.updateUserInfo();
	},
	/* 提交用户信息 */
	async updateUserInfo(){
		if(!this.userForm.name){
			this.$message.error('请填写姓名');
			return;
		}
		if(!this.userForm.school_class){
			this.$message.error('请填写班级');
			return;
		}
		if(!this.userForm.province){
			this.$message.error('请选择生源地');
			return;
		}
		if(!this.userForm.candidate){
			this.$message.error('请选择考生类型');
			return;
		}
		if(!this.userForm.subject_str){
			this.$message.error('请选择学科');
			return;
		}
		if(this.score_model.includes('1') && !this.userForm.cultural_score){
			this.$message.error('请填写高考分数');
			return;
		}
		if((this.score_model.includes('2') && !this.userForm.art_score)){
			this.$message.error('请填写艺术分');
			return;
		}
		if((this.score_model.includes('4') && !this.userForm.rank)){
			this.$message.error('请填写位次');
			return;
		}

		try {
			const res = await updateUserInfo({
				nickname:this.userForm.name,
				class:this.userForm.school_class,
				province:this.userForm.province,
				candidate_type:this.userForm.candidate,
				optional:this.userForm.subject_str,
				cultural_score:this.score_model.includes('1')?this.userForm.cultural_score:'',
				rank:this.score_model.includes('4')?this.userForm.rank:'',
				art_score:this.score_model.includes('2')?this.userForm.art_score?this.userForm.art_score:'':'',
			});

			if (res.code === 1) {
				let data = res.data;
				var userInfo = storage.get('userInfo');
				userInfo.nickname = this.userForm.name;
				userInfo.class = this.userForm.school_class;
				userInfo.province = this.userForm.province;
				userInfo.candidate_type = this.userForm.candidate;
				userInfo.optional = this.userForm.subject_str;
				userInfo.subject_name_str = this.subject_name_str;
				userInfo.cultural_score = this.userForm.cultural_score;
				userInfo.rank = this.userForm.rank;
				userInfo.art_score = this.userForm.art_score || 0.00;
				userInfo.modify_num = data;
				storage.set('userInfo',userInfo);
				this.$store.dispatch('user/updateUserInfo', userInfo);
				this.user = userInfo;
				this.register();
				this.getBatch();
			} else {
				this.$message.error(res.msg);
			}
		} catch (error) {
			this.$message.error('请求失败');
		}
	},
	/* 提交用户信息 */
	async getBatch(){
		try {
			const res = await getBatch();

			if (res.code === 1) {
				let result = res.data;
				this.volunteer_num = result.volunteer_num;
				let data = result.list;
				this.batch_list = data;
				setTimeout(() => {
					this.initChart();	
				}, 500);
			} else {
				this.$message.error(res.msg);
			}
		} catch (error) {
			this.$message.error('请求失败');
		}
	},
	// 图表
	initChart(){
		this.batch_list.forEach((item,index)=>{
			let total = item.sprint + item.steady + item.defend;
			// 注意：这里需要处理 total 可能为 0 的情况，以避免除以零的错误
			if (total === 0) {
				return;
			}
			let sprint = Math.round(item.sprint/total*100);
			let steady = Math.round(item.steady/total*100);
			let defend = Math.round(item.defend/total*100);
			// console.log(sprint)
			// console.log(steady)
			// console.log(defend)
			
			let chart_data = [{"name":"冲","value":sprint,"label":item.sprint},{"name":"稳","value":steady,"label":item.steady},{"name":"保","value":defend,"label":item.defend}]
			const chartContainer = document.getElementById('echartsContainer'+index)
			
			if(chartContainer){
				var isChart = echarts.getInstanceByDom(chartContainer);
				if (isChart) {
					isChart.dispose();
				}
				const myChart = echarts.init(chartContainer);
				const option = {
					tooltip: {
						trigger: 'item',
						formatter: '{d}%'
					},
					legend: {
						bottom: '8%',
						left: 'center'
					},
					series: [
						{
						name: '',
						type: 'pie',
						radius: ['45%', '64%'],
						center: ['48%', '46%'],
						data: chart_data,
						itemStyle: {
							color: function(params) {
								// 根据数据值设置颜色
								if (params.name == '冲') {
									return '#FA5151';
								} else if (params.name == '稳') {
									return '#FF8F1F';
								} else if(params.name == '保') {
									return '#00B578';
								}
							}
						},
						animationEasing: 'cubicInOut',
						animationDuration: 2600,
						animation: false,
						label:{
							show: true,
							formatter: function(params) {
								// 返回name和label，这里假设item对象在外部定义并包含了sprint, steady, defend等属性
								return params.name + params.data.label;
							}
						},
						labelLine :{show:true}
						}
					]
				};
				
				myChart.setOption(option);
			} else {
				console.error('图表容器未找到！');
			}
		})
	},
	toAi(id,type,title,year){
		// 构造路由对象，带参数
		let route = {
			name: 'ai', // 使用路由名称进行跳转
			query: { // 使用params传递参数时，参数不会显示在URL中
				id: id,
				year: year,
				type: type,
				title: title,
			}
		};
		// 使用Vue Router进行页面跳转
		this.$router.push(route);
	},
	toDataList(id,type,title,year){
		let url = type=='1'?'collegeSpecial':'collegeSpecialGroup';

		// 构造路由对象，带参数
		let route = {
			name: url, // 使用路由名称进行跳转
			query: { // 使用params传递参数时，参数不会显示在URL中
				id: id,
				year: year,
				title: title,
			}
		};
		
		// 使用Vue Router进行页面跳转
		this.$router.push(route);
	},
	toVolunteer(id,type){
		let name;
		if(type == 1){
			name = 'volTwoAlreadyVol';
		}else{
			name = 'alreadyVol';
		}
		const route = {
			name: name,
			query: { id: id }
		};
		this.$router.push(route);
		// window.open(route.href, '_blank');
	},
	filling(){
		if(!this.isLoggedIn || !isVip || !isInfoComplete){
			return;
		}
		this.changeTabs('A');
	},
	jumpTo(key){
		if(key === 'test'){
			this.$message.error('暂未开放');
			return
		}
		this.$router.push({name: key});
	}
  },
};
</script>
<style scoped lang="scss" src="./index.scss" />
<style lang="scss" scoped>
.main{
	min-height: 100vh;
	max-width: 1940px;
	background-image: linear-gradient(to bottom, #FFE7DF,#F9F9F9 80%);
	background-repeat: no-repeat;
	background-color: #F9F9F9 !important;
}
.home_box{
	min-height: 100vh;
	padding: 30px ;
	max-width: 1180px;
	margin: 0 auto;
	// background-image: linear-gradient(to bottom, #FFE7DF,#F9F9F9 80%);
	
	.home_top{
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		align-items: center;
		.home_top_right{
			cursor: pointer;
			width: 160px;
			height: 40px;
			border-radius: 10px;
			background: linear-gradient(270deg, #F36932 0%, #E6200C 100%), #FFEFE9;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 18px;
			font-weight: 500;
			color: #fff;
		}
	}
	
	// tab 切换
	.home_main{
		margin-top: 30px;
		
		.A_main{
			display: flex;
			.home_top_swiper {
			  width: 63%;
				margin-right: 20px;
			  // background-color: #9b0e12;
			  // width: 550px;
			  // swiper左右按钮
			  /deep/ .el-car {
			    width: 100%;
			    height: 100%;
				border-radius: 10px;
			  }
			  img {
			    width: 100%;
			    height: 100%;
			    // object-fit: cover;
			  }
			  /deep/.el-carousel__item{
				border-radius: 10px;
			  }
			  /deep/.el-carousel__button{
				  border-radius: 10px;
				  height: 6px;
			  }
			  /deep/.el-carousel__arrow {
			    // background-color: #30384d;
			    width: 70px;
			    height: 70px;
			    // border-radius: 0px;
			  }
			  // 左右按钮图标
			  /deep/.el-icon-arrow-left {
			    font-size: 40px;
			  }
			  /deep/.el-icon-arrow-right {
			    font-size: 40px;
			  }
			}
			
			.A_form{
				flex: 1;
				height: 390px;
				margin: 0px 4px;
				border-radius: 10px;
				background: linear-gradient(133deg, #FFEFEF 1%, #FFFFFF 53%), #FFFFFF;
				box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
				overflow: hidden;
				.A_form_ul{
					padding: 20px;
					.A_form_ul_lia{
						font-size: 32px;
						font-weight: bold;
					}
					.A_form_ul_lib{
						font-size: 20px;
						color: #222423;
					}
					.A_form_ul_lic{
						margin-top: 20px;
						/deep/ .el-input__inner {
							height: 50px;
						}
						.A_form_ul_lic_a{
							display: flex;
							margin-top: 20px;
							.btn{
								flex: 1;
								margin-left: 20px;
								box-sizing: border-box;
							}
						}
					}
					
					.A_form_ul_lid{
						margin-top: 20px;
						.A_form_ul_lid_a{
							display: flex;
							align-items: center;
							font-size: 16px;
							cursor: pointer;
							.quan {
								position: relative; /* 设置相对定位，以便伪元素可以相对于它定位 */
								width: 14px;
								height: 14px;
								border-radius: 50%;
								border: 1px solid #333333;
								margin-right: 10px;
								margin-left: 10px;
							}

							.agreement::before {
								content: ""; /* 伪元素需要内容属性，即使它是空的 */
								position: absolute; /* 绝对定位相对于最近的定位父元素（这里是 .quan） */
								top: 50%; /* 从顶部向下偏移50% */
								left: 50%; /* 从左侧向右偏移50% */
								width: 8px; /* 设置伪元素的宽度 */
								height: 8px; /* 设置伪元素的高度 */
								margin-top: -4px; /* 向上偏移自身高度的一半，以垂直居中 */
								margin-left: -4px; /* 向左偏移自身宽度的一半，以水平居中 */
								border-radius: 50%; /* 圆形 */
								background-color:  #E6200C; /* 红色背景 */
							}
							.quanright{
								display: flex;
								span{
									color: #E7240E;
								}
							}
						}
					}
					.A_form_ul_lie{
						margin-top: 30px;
						.A_form_ul_liea{
							width: 100%;
							height: 50px;
							background: linear-gradient(270deg, #F36932 0%, #E6200C 100%), #F16334;
							display: flex;
							justify-content: center;
							align-items: center;
							font-size: 20px;
							color: #fff;
						}
					}
					.A_form_ul_lieJh{
						display: flex;
						display: -webkit-box;
						display: -webkit-flex;
						display: -ms-flexbox;
						display: flex;
						-webkit-box-pack: justify;
						-webkit-justify-content: space-between;
						-ms-flex-pack: justify;
						margin-top: 40px;
						cursor: pointer;
						.A_form_ul_liea{
							width: 150px;
							height: 50px;
							background: linear-gradient(270deg, #F36932 0%, #E6200C 100%), #F16334;
							display: flex;
							justify-content: center;
							align-items: center;
							font-size: 20px;
							color: #fff;
						}
						.A_form_ul_liea:nth-child(1){
							margin-right: 20px;
							background: none!important;
							border: 1px solid  #EE4C28;
							color: #EE4C28;
						}
					}
					
				}
			}
		}
		
		
		
		
	}
}



// 志愿表
.zhiyuanbox{
	
	.zhiyuanlist{
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		align-items: center;
		height: 144px;
		padding: 0 30px;
		border-radius: 10px;
		background: linear-gradient(96deg, #FFEFEF 0%, #FFFFFF 27%), linear-gradient(96deg, #EAF4FF 0%, #FFFFFF 45%), #FFFFFF;
		box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
		margin-bottom: 20px;
		.zhiyuanlist_l{
			.zhiyuanlist_l_1{
				display: flex;
				margin-top: 10px;
				div{
					margin-right: 40px;
					font-size: 20px;
					color: #666666;
				}
			}
		}
		
		.zhiyuanlist_r{
			display: flex;
			cursor: pointer;
			.zhiyuanlist_r_de{
				
				img{
					width: 20px;
					height: 20px;
					margin-right: 2px;
				}
				margin-left: 20px;
				display: flex;
				align-items: center;
				font-size: 14px;
			}
		}
	}
}
// <div class="zhiyuanbox">
// 					  <div class="zhiyuanlist" v-for="i in [1,2,3]">
// 						  <div>
// 							  <div>志愿表</div>
// 							  <div>
// 								  <div>山东省|普通|物化生</div>
// 								  <div>数据年份2024 本科批</div>
// 							  </div>
// 						  </div>
// 						  <div>
// 							<el-button type="danger" plain>查看</el-button>
// 							<el-button   class="elbuttontext-white" type="text" icon="el-icon-delete" @click="detailClick(scope.row)">明细</el-button>
// 						  </div>
// 					  </div>
// 				  </div>

// 填报须知
.A_main_notice{
	border-radius: 10px;
	background: rgba(0, 0, 0, 0.04);
	box-sizing: border-box;
	border: 1px dashed #FF0000;
	padding: 20px;
	.A_main_notice_tit{
		font-weight: bold;
		font-size: 22px;
	}
	ul{
		li{
			font-size: 14px;
		}
	}
}



::v-deep .el-tabs__item.is-active {
  color: red;//选中
  opacity: 1;
  font-weight: bold;
}
 ::v-deep .el-tabs__active-bar{
	 height: 4px;
	 background: #FF0000;
 }
 ::v-deep .el-tabs__item{
	 font-size: 20px;
 }
::v-deep .el-tabs__item:hover {
  color: red;//悬浮
  cursor: pointer;
  opacity: 1;
}
::v-deep .el-tabs__nav-wrap::after{
	background-color:#F9E6E0
}
::v-deep .elbuttontext-white{
	color: #000;
}
.xian{
	height: 1px;
	background-color:  #F2EAE8;
	margin: 40px 0;
}
.buttom_div {
	   display: block;
	}
.A_form_ul_liea {
  cursor: pointer; /* 设置鼠标样式为手型 */
}

.tooltip-content img {
  display: block; /* 确保图片显示为块级元素，以便控制尺寸 */
  margin: 0 auto; /* 图片居中显示 */
}
</style>