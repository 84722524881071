import Vue from 'vue';
import Vuex from 'vuex';
import user from '../modules/user'; // 假设user.js位于modules文件夹中

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user // 将user模块注册到根store中
  }
});