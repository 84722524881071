<template>
  <div class="school-tabs">
    <ul class="tabs">
      <li :class="activeTab === index?'active':''" v-for="(item,index) in tabs" :key="index" @click="activeTab = index">{{ item.title }}</li>
    </ul>
    <!-- <component :is="tabs[activeTab].components" /> -->
    <template v-if="activeTab === 0">
      <school-info :info="data" @active="activeTab = 1"></school-info>
    </template>
    <template v-if="activeTab === 1">
      <school-content :data="data.content"></school-content>
    </template>
    <template v-if="activeTab === 2">
      <school-special :id="data.id"></school-special>
    </template>
    <template v-if="activeTab === 3">
      <school-enrollment :id="data.id"></school-enrollment>
    </template>
    <template v-if="activeTab === 4">
      <school-employment :data="data.employment_content" :date="data.updatetime"></school-employment>
    </template>
  </div>
</template>
  
<script>
  import { ref, watch, onMounted } from 'vue';
  import SchoolInfo from './SchoolInfo.vue';
  import SchoolContent from './SchoolContent.vue';
  import SchoolSpecial from './SchoolSpecial.vue';
  import SchoolEnrollment from './SchoolEnrollment.vue';
  import SchoolEmployment from './SchoolEmployment.vue';
  export default {
    components: {
      SchoolInfo,
      SchoolContent,
      SchoolSpecial,
      SchoolEnrollment,
      SchoolEmployment,
    },
    props: {
      data:{
        type: Object
      }
    },
    setup(props) {
      const activeTab = ref(0);
      const tabs = ref([
          {
            title: '学校概况',
            components: 'SchoolInfo'
          },
          {
            title: '学校介绍',
            components: 'SchoolContent'
          },
          {
            title: '开设专业',
            components: 'SchoolInfo'
          },
          {
            title: '招生简章',
            components: 'SchoolInfo'
          },
          {
            title: '学校就业',
            components: 'SchoolEmployment'
          }
        ]);
      return {
        activeTab,
        tabs,
      }
    }
  }
</script>
<style lang="scss" scoped>
  .school-tabs{
    width: 1180px;
    margin:0 auto;
    padding: 13px 0 56px;
  }
  .tabs{
    display: flex;
    justify-content: space-between;
    height: 45px;
    margin-bottom: 31px;
    background-color: #F15F2D;
    color: #fff;
    font-size: 20px;
    & > li{
      width: 20%;
      height:100%;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 20px;
      letter-spacing: 0.009000000000000001em;
    }
  }
  .active{
    opacity: 0.8;
    background-color: #a5401fcc;
  }
</style>
  