<template>
  <div class="header-image">
    <div class="bgc">
      <span>{{ text }}</span>
    </div>
  </div>
  
</template>
  
<script>
  export default {
    name: 'StripesAd',
    props: {
      text: String
    },
    data() {
      return {
        
      }
    },
    mounted() {
      
    },
    methods: {
      
    }
  }
</script>
<style lang="scss" scoped>
  .header-image{
    width: 100%;
    height: 160px;
    // background-image: url('@/assets/img/bgc.jpg');
    background: linear-gradient(to right, #FFE7DF, #F9F9F9 80%);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-bottom: 43px;
  }
  .bgc{
    width: 1180px;
    height: 160px;
    margin: 0 auto;
    
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    line-height: 30px;
    color: #F15F2D;
  }
</style>
  