var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home_box"},[_c('div',{staticClass:"vol-main"},[_c('div',{staticClass:"vol-cen",attrs:{"id":"print-div"}},[_vm._m(0),_vm._m(1),_c('div',_vm._l(([1,2,3,1,2,3]),function(item){return _c('div',{staticClass:"tzy-college-tr"},[_vm._m(2,true),_vm._m(3,true),_vm._m(4,true),_vm._m(5,true),_vm._m(6,true),_vm._m(7,true),_c('div',{staticClass:"action college-tr-mask-white",staticStyle:{"width":"6.9%"}})])}),0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"alreadyvol-top"},[_c('div',{staticClass:"alr-p2"},[_c('div',{staticClass:"alr-p2-l"},[_c('div',{staticClass:"alr-p2-ll"},[_vm._v("山东 普通类 x x x 590分")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"48px"}},[_c('div',{staticClass:"tzy-container-th"},[_c('div',{staticClass:"pl20",staticStyle:{"width":"6%"}},[_vm._v(" 排序 ")]),_c('div',{staticClass:"pl20",staticStyle:{"width":"6%"}},[_vm._v(" 录取概率 ")]),_c('div',{staticStyle:{"width":"15%","text-align":"left","padding-left":"20px"}},[_vm._v(" 专业 ")]),_c('div',{staticStyle:{"width":"18.1%","text-align":"left","padding-left":"20px"}},[_vm._v(" 院校 ")]),_c('div',{staticStyle:{"width":"11.2%"}},[_vm._v(" 2024年招生计划 ")]),_c('div',{staticClass:"tzy-history-tr",staticStyle:{"width":"36.8%"}},[_c('div',{staticClass:"tzy-container-td"},[_vm._v("历年")]),_c('div',{staticClass:"tzy-container-td"},[_vm._v(" 2023 ")]),_c('div',{staticClass:"tzy-container-td"},[_vm._v(" 2022 ")]),_c('div',{staticClass:"tzy-container-td"},[_vm._v(" 2021 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"percent",staticStyle:{"width":"6%"}},[_c('div',{staticClass:"percentnumpx",staticStyle:{"position":"relative"}},[_c('div',{staticStyle:{"font-size":"26px","color":"#000"}},[_vm._v("100")]),_c('div',{staticStyle:{"position":"relative"}},[_c('div',{staticClass:"el-icon-arrow-up up"}),_c('div',{staticClass:"el-icon-arrow-down up"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"percent",staticStyle:{"width":"6%"}},[_c('h1',{staticClass:"percent-num"},[_c('span',[_vm._v("1")]),_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("%")])]),_c('p',{staticClass:"percent-text"},[_vm._v("极大风险")]),_c('p',{staticClass:"ifrush A"},[_c('span',{staticClass:"span1"},[_vm._v("冲")]),_c('span',{staticClass:"span2"},[_vm._v("冲")]),_c('span',{staticClass:"span3"},[_vm._v("冲")])]),_c('p',{},[_vm._v("预测分：555")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"major",staticStyle:{"width":"15%","text-align":"left"}},[_c('div',{staticClass:"major-info"},[_c('a',{staticClass:"major-name"},[_c('span',[_vm._v("安全工程")])]),_c('div',{staticClass:"remark one"},[_vm._v("(呈贡校区)")])]),_c('p',{staticClass:"major-intr"},[_vm._v(" 代码： "),_c('span',{staticClass:"code"},[_vm._v("B7")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"college",staticStyle:{"width":"18.1%","text-align":"left"}},[_c('div',{staticClass:"college-info"},[_c('div',[_c('a',{staticClass:"college-name",attrs:{"href":"javascript:void(0)"}},[_c('span',[_vm._v("中国石油大学(北京)克拉玛依校区")])]),_c('div',{staticClass:"remark"},[_vm._v(" (本科)(新疆公办) ")])])]),_c('div',{staticClass:"college-intr"},[_c('p',[_vm._v(" 代码： "),_c('span',{staticClass:"code"},[_vm._v(" H414 ")]),_c('span',[_c('span',{staticClass:"split"},[_vm._v("|")]),_vm._v(" 211 / 国重点 / 保研 / 双一流 ")]),_c('span',[_c('span',{staticClass:"split"},[_vm._v("|")]),_vm._v(" 新疆 / 克拉玛依市 / 理工 / 公办 / 排名 86 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"plan",staticStyle:{"width":"11.2%"}},[_c('span',{staticClass:"plan-num",staticStyle:{"text-rendering":"optimizelegibility","font-feature-settings":"\"kern\"","font-kerning":"normal"}},[_vm._v(" 3 "),_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("人")])]),_c('p',[_c('span',{staticClass:"learn-year"},[_vm._v(" 4年  ￥3500 ")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('span',{staticClass:"choose-subject"},[_vm._v(" 物 和 化 ")]),_vm._v(" "),_c('span')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tzy-history-tr",staticStyle:{"width":"36.8%"},attrs:{"default-selected":"理科"}},[_c('div',{staticClass:"tzy-container-td solid"},[_c('div',{staticClass:"tzy-container-td-col"},[_c('span',[_vm._v("录取人数")])]),_c('div',{staticClass:"tzy-container-td-col"},[_c('span',[_vm._v("计划人数")])]),_c('div',{staticClass:"tzy-container-td-col"},[_c('span',[_vm._v("最低分")])]),_c('div',{staticClass:"tzy-container-td-col"},[_c('span',[_vm._v("最低位次")])])]),_c('div',{staticClass:"tzy-container-td solid"},[_c('div',{staticClass:"tzy-container-td-col"},[_vm._v("3")]),_c('div',{staticClass:"tzy-container-td-col"},[_vm._v(" 3 ")]),_c('div',{staticClass:"tzy-container-td-col"},[_vm._v(" 570 ")]),_c('div',{staticClass:"tzy-container-td-col"},[_vm._v("49200")])]),_c('div',{staticClass:"tzy-container-td solid"},[_c('div',{staticClass:"tzy-container-td-col"},[_vm._v("3")]),_c('div',{staticClass:"tzy-container-td-col"},[_vm._v(" 3 ")]),_c('div',{staticClass:"tzy-container-td-col"},[_vm._v(" 570 ")]),_c('div',{staticClass:"tzy-container-td-col"},[_vm._v("49200")])]),_c('div',{staticClass:"tzy-container-td solid"},[_c('div',{staticClass:"tzy-container-td-col"},[_vm._v("未招生")])])])
}]

export { render, staticRenderFns }