<template>
  <div class="main">
    <school-header :data="data"></school-header>
    <school-tabs :data="data"></school-tabs>
  </div>
</template>
<script>
import router from '@/router' // 引入Vue Router实例
  import { getSchoolDetail } from '@/api/home';
  import SchoolTabs from '@/components/SchoolTabs.vue';
  import SchoolHeader from '@/components/SchoolHeader.vue';
export default {
  components: {
    SchoolHeader,
    SchoolTabs
  },
  data() {
    return {
      data: {}, // 用于存储学校详情的数据
      id: this.$route.query.id
      // 从路由参数中获取学校ID
    };
  },
  methods: {
    async fetchSchoolDetail() {
      console.log(this.id);
      try {
        const response = await getSchoolDetail({ id: this.id });
        if (response.code === 1) {
          this.data = response.data;
        }
      } catch (error) {
        // 处理错误
      }
    },
    handleTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // 可选的，使用平滑滚动效果
      });
    }
  },
  mounted() {
    // 组件加载完成时执行的钩子函数
    this.fetchSchoolDetail(); // 获取学校详情
    this.handleTop(); // 滚动到页面顶部（如果需要）
  },
};
</script>
<!--声明语言，并且添加scoped-->
<style lang="scss" scoped>
  .main{
    background-color: #F5F5F5;
  }
</style>