<template>
	<div class="home_box">
		<!-- <div class="home_top">
		  <div class="home_top_left">
			  <img src="../../assets/让学习点燃新希望_01.png" alt="" srcset="" />
		  </div>
		  <div class="home_top_right">
			  注册/登录
		  </div>
	  </div> -->

		<div class="vol-main">
			<!-- <div class="A_main_notice">
				<div class="A_main_notice_tit"><span>!</span>重要提示</div>
				<ul>
					<li> 注:以上志愿表为模拟埴报志愿表，正式填报请登录省考试院指定报网站;</li>
					<li> 申明:智能推荐使用当年招生计划和参考历年录取数据的志愿模拟填报平台</li>
					<li>参考数据均来自省考试院，正式填报过程中如遇到数据错误，请参阅省考试院发布的相关招生计划书籍或拨打客服热线。</li>
				</ul>
			</div> -->
			<div class="vol-cen" id="print-div">
				<div class="alreadyvol-top">
					<!-- <div class="alr-p1">志愿表14 <span>全部志愿表</span></div> -->
					<div class="alr-p2">
						<div class="alr-p2-l">
							<div class="alr-p2-ll">山东 普通类 x x x 590分</div>
							<!-- <div class="alr-p2-lr">
								<p><span class="span1"></span>冲(4)</p>
								<p><span class="span2"></span>稳(2)</p>
								<p><span class="span3"></span>保(4)</p>
							</div> -->
						</div>
						<!-- <div class="alr-p2-r">
							<div><img src="../../assets/img/px.png" alt="" />一键排序</div>
							<el-button>返回</el-button>
							<el-button>保存</el-button>
							<el-button>下载</el-button>
							<el-button @click="printClick">打印</el-button>
						</div> -->
					</div>
				</div>
				<div style="height: 48px;">
					<div class="tzy-container-th">
						<div class="pl20" style="width: 6%;">
							排序
							
						</div>
						<div class="pl20" style="width: 6%;">
							录取概率
							
						</div>
						<div style="width: 15%; text-align: left; padding-left: 20px;">
							专业

						</div>
						<div style="width: 18.1%; text-align: left; padding-left: 20px;">
							院校
							
						</div>
						<div style="width: 11.2%;">
							2024年招生计划
							
						</div>
						<div class="tzy-history-tr" style="width: 36.8%;">
							<div class="tzy-container-td">历年</div>
							<div class="tzy-container-td">
								2023
								
							</div>
							<div class="tzy-container-td">
								2022
								
							</div>
							<div class="tzy-container-td">
								2021
								
							</div>
						</div>
						<!-- <div style="width: 6.9%; text-align: left; padding-left: 20px;">
							拖动/删除
						</div> -->
					</div>
				</div>


				<div>
					<div class="tzy-college-tr" v-for="item in [1,2,3,1,2,3]">
						<div class="percent" style="width: 6%;">
							<div class="percentnumpx"style="position: relative;">
								<div style="font-size: 26px;color: #000;">100</div>
								<div style="position: relative;">
									<div class="el-icon-arrow-up up" ></div>
									<div class="el-icon-arrow-down up" ></div>
								</div>
							</div>
						</div>
						
						<div class="percent" style="width: 6%;">
							<h1 class="percent-num">
								<span>1</span>
								<span style="font-size: 12px;">%</span>
							</h1>
							<p class="percent-text">极大风险</p>
							<p class="ifrush A">
								<span class="span1">冲</span>
								<span class="span2">冲</span>
								<span class="span3">冲</span>
							</p>
							<p class="">预测分：555</p>
						</div>
						<div class="major" style="width: 15%; text-align: left;">
							<div class="major-info">
								<a class="major-name"><span>安全工程</span></a>
								<div class="remark one">(呈贡校区)</div>
							</div>
							<p class="major-intr"> 代码： <span class="code">B7</span> </p>
						</div>
						<div class="college" style="width: 18.1%; text-align: left;">
							<div class="college-info">
								<div>
									<a href="javascript:void(0)" class="college-name">
										<span>中国石油大学(北京)克拉玛依校区</span></a>
									<div class="remark">
										(本科)(新疆公办)
									</div>
								</div>
							</div>
							<div class="college-intr">
								<p> 代码：
									<span class="code"> H414 </span>
									<span>
										<span class="split">|</span>
										211 / 国重点 / 保研 / 双一流
									</span>
									<span>
										<span class="split">|</span>
										新疆 / 克拉玛依市 / 理工 /
										公办 / 排名 86
									</span>
								</p>
							</div>
						</div>
						<div class="plan" style="width: 11.2%;">
							<span class="plan-num"
								style="text-rendering: optimizelegibility; font-feature-settings: &quot;kern&quot;; font-kerning: normal;">
								3 <span style="font-size: 12px;">人</span>
							</span>
							<p><span class="learn-year">
									4年&nbsp;&nbsp;￥3500
								</span> <br> <span class="choose-subject">
									物 和 化
								</span> <span></span></p>
						</div>
						<div class="tzy-history-tr" default-selected="理科" style="width: 36.8%;">
							<div class="tzy-container-td solid">
								<div class="tzy-container-td-col"><span>录取人数</span></div>
								<div class="tzy-container-td-col"><span>计划人数</span></div>
								<div class="tzy-container-td-col"><span>最低分</span>
								</div>
								<div class="tzy-container-td-col"><span>最低位次</span></div>

							</div>
							<div class="tzy-container-td solid">
								<div class="tzy-container-td-col">3</div>
								<div class="tzy-container-td-col"> 3 </div>
								<div class="tzy-container-td-col"> 570 </div>
								<div class="tzy-container-td-col">49200</div>

							</div>
							<div class="tzy-container-td solid">
								<div class="tzy-container-td-col">3</div>
								<div class="tzy-container-td-col"> 3 </div>
								<div class="tzy-container-td-col"> 570 </div>
								<div class="tzy-container-td-col">49200</div>

							</div>
							<div class="tzy-container-td solid">
								<div class="tzy-container-td-col">未招生</div>
							</div>
						</div>
						<div class="action college-tr-mask-white" style="width: 6.9%;">
							<!-- <div class="rightyd">
								<img src="../../assets/img/shou.png" alt="" />
								<img src="../../assets/img/del.png" alt="" />
							</div> -->
						</div>
					</div>
				</div>






			</div>

		</div>


		<!-- 悬浮框 -->
		<!-- <div id="tzy-selected-pop" :class="rightShow?'slide-out':''" cart-list="">
			<div class="shoppingCarLeft pull-left">
				<div class="foldBtn" @click="pullleftClick">
					<p class="f16">{{rightShow?'收起':'展开'}}志愿表</p> <span class="f18">0</span>
				</div>
				<button class="yzy-button save">保存</button>
				<button class="yzy-button save safe" style="border-top: 0px;" @click="topClick">顶部</button>
			</div>
			<div class="shoppingCarRight pull-right" style="height: 799px;">
				<div class="searchInput el-input el-input--suffix"><input type="text" autocomplete="off"
						placeholder="院校/专业搜索" class="el-input__inner"><span class="el-input__suffix"><span
							class="el-input__suffix-inner"><i class="el-input__icon el-icon-search f16"
								style="cursor: pointer;"></i></span></span>
				</div>
				<div class="cartList" style="height: 669px;">
					<div class="cartListItem">
						<div class="cartCollege">
							<div class="college-title dataType pull-left chong"><span class="college-num">1</span></div>
							<span class="cartCollegeName">中国石油大学(北京)克拉玛依校区(新疆公办)</span>
							<img src="../../assets/img/del.png" alt="" />
						</div>
						<div class="professionItem">
							<p class="professionName ml30"><span>安全工程</span>
							</p>
						</div>
					</div>
				</div>
				<button class="yzy-button creatsuccess">
					<p>保存志愿表</p> <span>( 已填0个志愿 )</span>
				</button>
			</div>
		</div> -->



	</div>
</template>

<script>
	import * as echarts from 'echarts';
	import {
		lunbotu,
		jituanjianjie,
		shipinshouye,
		ywtx,
		ssfw,
		jjrongyu,
		qxpp,
		homeimage
	} from "@/api/home";
	export default {
		components: {},

		data() {
			return {
				rightShow: false, //右侧弹框展示与隐藏
				slidervalue: [600, 400],
				activeName: 'A',
				xuankeData: ['物理', '化学', '生物', '历史', '地理'],
				login: {
					radio: ''
				},
				form: {
					name: '',
					region: '',
					date1: '',
					date2: '',
					delivery: false,
					type: [],
					resource: '',
					desc: ''
				}
			};
		},
		created() {},
		mounted() {
			this.pins()
		},
		methods: {
			// 打印pdf
			pins() {
			  var printElement = document.getElementById('print-div');
			  //    // getElementsByTagName() 方法可返回带有指定标签名的对象的集合。
			  //    // 拿到被打印的body的元素后通过zoom属性调整大小
			  document.getElementsByTagName('body')[0].style.zoom=0.45;
			
			  let bdhtml = window.document.body.innerHTML;
			  let sprnstr = "<!--startprint-->"; //开始打印标识字符串有17个字符
			  let eprnstr = "<!--endprint-->"; //结束打印标识字符串
			  let prnhtml = bdhtml.substr(bdhtml.indexOf(sprnstr) + 17); //从开始打印标识之后的内容
			  prnhtml = prnhtml.substring(0, prnhtml.indexOf(eprnstr)); //截取开始标识和结束标识之间的内容
			  // window.document.body.innerHTML = prnhtml; //把需要打印的指定内容赋给body.innerHTML
			  // this.pagesetup_null();
			  // window.document.body.innerHTML = bdhtml; //重新给页面内容赋值；
			  window.print(); //调用浏览器的打印功能打印指定区域
			  return false;
			},
			formatTooltip() {
				this.$nextTick(() => {
					this.$refs.slider.setPosition(this.slidervalue)
				})
			},
			//点击右侧悬浮框
			pullleftClick() {
				this.rightShow = !this.rightShow
			},
			// 页面返回到顶部
			topClick() {
				const scrollToTop = setInterval(() => {
					const currentPosition = document.documentElement.scrollTop || document.body.scrollTop;
					if (currentPosition > 0) {
						window.requestAnimationFrame(() => {
							window.scrollTo(0, currentPosition - currentPosition / 8);
						});
					} else {
						clearInterval(scrollToTop);
					}
				}, 16); // 16ms 是一个合理的帧率间隔，可以保持动画的流畅
			}

		},
	};
</script>
<style scoped lang="scss" src="./index.scss" />
<style lang="scss" scoped>
	// 上面引入的css 只是表格样式
	
	//新增s
	
	.alreadyvol-top{
		margin-bottom: 20px;
		.alr-p1{
			display: flex;
			align-items: center;
			font-size: 19px;
			font-weight: bold;
			span{
				color: #FF0000;
				font-size: 14px;
				margin-left: 10px;
			}
		}
		.alr-p2{
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-pack: justify;
			-webkit-justify-content: space-between;
			-ms-flex-pack: justify;
			align-items: center;
			.alr-p2-l{
				display: flex;
				font-size: 16px;
				align-items: center;
				.alr-p2-ll{
					margin-right: 50px;
				}
				.alr-p2-lr{
					display: flex;
					align-items: center;
					p{
						display: flex;
						align-items: center;
						margin-right: 30px;
						span{
							display: block;
							width: 14px;
							height: 14px;
							color: #FF0000;
							border-radius: 50%;
						}
						.span1{
							background-color:#FF0000;
						}
						.span2{
							background-color:#FF9423;
						}
						.span3{
							background-color:#00B578;
						}
						
					}
				}
			}
			.alr-p2-r{
				display: flex;font-size: 16px;
				align-items: center;
				div{
					margin-right: 30px;
				}
				img{
					width: 15px;
					height: 15px;
					margin-right: 6px;
				}
			}
		}
	}
	
	//新增e
	
	
	
	.home_box {
		min-height: 100vh;
		padding: 30px;
		max-width: 1940px;
		margin: 0 auto;
		background-image: linear-gradient(to bottom, #FFE7DF, #F9F9F9 80%);
		background-repeat: no-repeat;
		background-color: #F9F9F9 !important;
		
	}

	/deep/.el-slider__bar {
		background-color: #F0673A;
	}

	/deep/.el-slider__button {
		border: 2px solid #F0673A;
	}
	// 填报须知
	.A_main_notice{
		border-radius: 10px;
		background: rgba(0, 0, 0, 0.04);
		box-sizing: border-box;
		border: 1px dashed #FF0000;
		padding: 15px;
		.A_main_notice_tit{
			font-weight: bold;
			font-size: 16px;
			display: flex;
			align-items: center;
			margin-bottom: 6px;
			span{
				display: block;
				width: 14px;
				height: 14px;
				border-radius: 50%;
				background-color: red;
				color: #fff;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-right: 6px;
				font-size: 14px;
			}
		}
		ul{
			li{
				font-size: 14px;
				margin-left: 20px;
			}
		}
	}
	.buttom_div{
		   display: none;
	}
</style>
<style media="print">
  @media print {
   html {
     height: auto;
     /* background-color: #ffffff; */
     margin: 0px;
	 padding: 0;
   }
   body {
	   /* background-color: #ffffff; */
     /* border: solid 1px #ffffff; */
     /* margin: 10mm 15mm 10mm 15mm; */
	
   }
   .buttom_div{
	   display: none;
   }
  }
</style>
