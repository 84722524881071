<template>
	<div class="home_box">
		<!-- <div class="home_top">
		  <div class="home_top_left">
			  <img src="../../assets/让学习点燃新希望_01.png" alt="" srcset="" />
		  </div>
		  <div class="home_top_right">
			  注册/登录
		  </div>
	  </div> -->

		<div class="home_main">

			<div class="alllist">
				<div class="alllistul">
					<div class="alllistul-l">
						<div>
							<span>{{userInfo.province?userInfo.province:''}}</span>
							<span>{{userInfo.candidate_type?userInfo.candidate_type:''}}</span>
							<span>{{userInfo?userInfo.subject_name_str:''}}</span>
							<span>{{userInfo.cultural_score?userInfo.cultural_score:''}}</span>
						</div>
					</div>
					<div class="alllistul-r" @click="toHome('C')"><span>修改</span><img src="../../assets/img/bi.png" alt="" /></div>
				</div>
				<div class="alllistul">
					<div class="alllistul-l">
						<div>志愿表({{volunteer_num}}) </div>
						<div class="font-14" style="color: rgba(0, 0, 0, 0.6);">数据更新后自动新建志愿表</div>
					</div>
					<div class="alllistul-r" @click="toHome('B')"><span class="span1">全部</span><img src="../../assets/img/all.png" alt="" />
					</div>
				</div>

				<div class="alllistulNew">
					<div class="alllistulNew_t">
						<span class="span1">AI一键填报</span>
					</div>
					<div class="allts">
						<p class="p1"><span>!</span>AI一键填报不会删除自主填报志愿</p>
						<p class="p2"><span>!</span>AI填报推荐仅供参考,请结合自身情况谨慎填报</p>
					</div>
					
					<div class="formstule">
							<el-form label-width="80px">
								<el-row :gutter="21">
								   <!-- <el-col :span="9">
										<el-form-item label="*姓名">
										  <el-input v-model="form.name"></el-input>
										</el-form-item>
									</el-col>
									<el-col :span="9">
										<el-form-item label="*学校班级">
										  <el-input v-model="form.name"></el-input>
										</el-form-item>
									</el-col> -->
									
									<el-col :span="9">
										<el-form-item label="选择批次">
										  <el-select v-model="category_id" placeholder="请选择" @change="getProvince">
										    <el-option v-for="item in batch_list" :label="item.name" :value="item.id"></el-option>
										  </el-select>
										</el-form-item>
									</el-col>
									<!-- <el-col :span="9">
										<el-form-item label="考生类型">
										  <el-select v-model="form.region" placeholder="请选择">
										    <el-option label="区域一" value="shanghai"></el-option>
										    <el-option label="区域二" value="beijing"></el-option>
										  </el-select>
										</el-form-item>
									</el-col> -->
								</el-row>
								
								<el-row :gutter="24">
									<el-col :span="12">
										<el-form-item label="意向城市">
											<el-cascader placeholder="选择意向城市" :options="city" :props="defaultPropscity" :show-all-levels="false" @change="changeCity">
											</el-cascader>
										</el-form-item>
									</el-col>
									<el-col :span="12">
										<el-form-item label="意向专业">
											<el-tag
												v-for="tag in special_arr"
												closable
												:disable-transitions="false"
												@close="handleSpecialClose(tag)">
												{{tag}}
												</el-tag>
												<el-input
												class="input-new-tag"
												v-if="showSpecial"
												v-model="keyword"
												ref="saveTagInput"
												size="small"
												@keyup.enter.native="handleSpecialConfirm"
												@blur="handleSpecialConfirm"
												>
												</el-input>
												<el-button v-else class="button-new-tag" size="small" @click="showSpecialInput">+ 添加</el-button>
										</el-form-item>
									</el-col>
								</el-row>
								<el-row :gutter="21" v-for="(item,index) in risk_level">
									<div class="bordernew">
										<div class="bordernew-l">
											<!-- <div class="tp1"><span :class="{'w1':item.name == '稳','w2':item.name == '保'}">{{ item.name }}</span>有20个符合意向</div> -->
											<div class="tp1"></div>
											<div class="tp2">
												<span :class="{'w1':item.name == '稳','w2':item.name == '保'}">{{ item.name }}</span>数量<el-input class="cinput" v-model="item.num"></el-input> {{ item.desc }}
											</div>
										</div>
										<div class="bordernew-l bordernew-r">
											<div class="tp1">符合意向数量不足时</div>
											<div class="tp2">
												<el-checkbox v-model="item.is_city">不限制城市</el-checkbox>
												<el-checkbox v-model="item.is_special">不限制专业</el-checkbox>
											</div>
										</div>
									</div>
								</el-row>
							</el-form>
					</div>
					<div class="alllistulNew_b">
						<div class="A_form_ul_lie A_form_ul_lieJh">
							<div class="A_form_ul_liea" @click="aiVolunteer">
								运行AI一键填报
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>






	</div>
</template>

<script>
	// import * as echarts from 'echarts';
	// import Chart from 'chart.js/auto';
	// import Swiper from "swiper";
	// import "swiper/css/swiper.min.css
	// import  {group} from '@/utils/fenlei'
	import { mapGetters } from 'vuex';
	import {
		getBatch,
		getAiInit,
		aiVolunteer,
	} from "@/api/home";
	export default {
		components: {},

		data() {
			return {
				
				defaultPropscity: {
				  children: 'children',
				  label: 'name',
				  value: 'name',
				  multiple: true,
				},
				volunteer_num: 0,
				batch_list: [],
				batch: '',
				category_id: '',
				year:'',
				batch_name:'',
				type:'',
				risk_level:[],

				city:[],
				city_arr:[],

				special_arr:[],
				keyword:'',
				showSpecial:false,
			};
		},
		computed: {
			...mapGetters('user',['userInfo']),
		},
		created() {
			this.getBatch();
		},
		mounted() {
		},
		activated() {
			this.volunteer_num= 0;
			this.batch_list= [];
			this.batch= '';
			this.category_id= '';
			this.year='';
			this.batch_name='';
			this.type='';
			this.risk_level=[];

			this.city=[];
			this.city_arr=[];

			this.special_arr=[];
			this.keyword='';
			this.showSpecial=false;
		},
		watch: { 
			'$route' (to, from) { 
				if(to.name === 'ai'){
					console.log("刷新")
					this.getBatch()
				}
				
			}
		},
		methods: {
			async getBatch(){
				try {
					const res = await getBatch();
					if (res.code === 1) {
						let result = res.data;
						this.volunteer_num = result.volunteer_num;
						let data = result.list;
						this.batch_list = data;
					} else {
						this.$message.error(res.msg);
					}
				} catch (error) {
					this.$message.error('请求失败');
				}
			},
			async getProvince(e){
				const item = this.batch_list.find(item => item.id === e);
				this.category_id = item.id;
				this.year = item.year;
				this.batch_name = item.name;
				this.type = item.data_model == '1'?'2':'1';
				try {
					const res = await getAiInit({
						category_id: this.category_id,
					});
					if (res.code === 1) {
						let data = res.data;
						this.city = data.city;
						let risk_level = data.risk_level;
						risk_level = risk_level.map(item => {
							return {
								...item, // 保留原有的字段
								is_city: false, // 添加 is_city 字段，并设置初始值（根据需要修改）
								is_special: false // 添加 is_special 字段，并设置初始值（根据需要修改）
							};
						});
						this.risk_level = risk_level;
					} else {
						this.$message.error(res.msg);
					}
				} catch (error) {
					this.$message.error('请求失败');
				}
			},
			changeCity(value) {
				// 清空之前的城市名数组
				this.city_arr = [];

				// 遍历选中的值（路径数组）
				value.forEach(path => {
				// 假设每个路径数组的最后一个元素是城市名
				const cityName = path[path.length - 1]; // 获取城市名
				this.city_arr.push(cityName); // 将城市名添加到数组中
				});
			},
			handleSpecialClose(tag) {
				this.special_arr.splice(this.special_arr.indexOf(tag), 1);
			},

			showSpecialInput() {
				this.showSpecial = true;
				this.$nextTick(_ => {
				this.$refs.saveTagInput.$refs.input.focus();
				});
			},

			handleSpecialConfirm() {
				let keyword = this.keyword.trim(); // 去除首尾空格
				if (keyword && !this.special_arr.includes(keyword)) { // 检查keyword是否为空且不在数组中
					this.special_arr.push(keyword); // 如果不重复，则添加到数组中
				}
				this.showSpecial = false;
				this.keyword = ''; // 重置输入框
			},
			async aiVolunteer(){
				let special_arr = this.special_arr;
				special_arr = special_arr.filter(item => item.trim() !== '');
				if(special_arr.length <= 0){
					this.$message.error('请添加至少一个专业关键词');
					return;
				}
				//判断志愿数量
				let total = this.risk_level.reduce((accumulator, currentValue) => {
					const numValue = Number(currentValue.num); // 使用Number函数进行转换
					return accumulator + numValue;
				}, 0); // 初始值为0
				if(total < 3){
					this.$message.error('志愿数量至少为3个');
					return;
				}
				try {
					const res = await aiVolunteer({
						batch_id: this.category_id,
						year: this.year,
						batch_name: this.batch_name,
						type: this.type,
						city_str: this.city_arr.length > 0 ? this.city_arr.join(',') : '',
        				special_str: special_arr.length > 0 ? special_arr.join(',') : '',
						risk_level: this.risk_level,
					});
					if (res.code === 1) {
						let id = res.data;
						let name;
						if(this.type == 1){
							name = 'volTwoAlreadyVol'
						}else{
							name = 'alreadyVol';

						}
						let route = {
							name: name, // 使用路由名称进行跳转
							query: { // 使用params传递参数时，参数不会显示在URL中
								id: id,
							}
						};
						// 使用Vue Router进行页面跳转
						this.$router.push(route);
					} else {
						this.$message.error(res.msg);
					}
				} catch (error) {
					this.$message.error('请求失败');
				}
			},
			toHome(tab){
				this.$router.push({
					path: '/',
					query: {
						tab: tab // 这里替换为你的 tab 值
					}
				});
			},






			handleClick(tab, event) {
				console.log(tab, event);
			},


			ljqingkao() {
				this.$router.push("/intoXueMeng/qiyejieshao");
			},
		},
	};
</script>
<style scoped lang="scss" src="./index.scss" />
<style lang="scss" scoped>
	.home_box {
		min-height: 100vh;
		padding: 30px;
		max-width: 1940px;
		margin: 0 auto;
		background-image: linear-gradient(to bottom, #FFE7DF, #F9F9F9 80%);
		background-repeat: no-repeat;
		background-color: #F9F9F9 !important;

		.home_top {
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-pack: justify;
			-webkit-justify-content: space-between;
			-ms-flex-pack: justify;
			align-items: center;

			.home_top_right {
				width: 160px;
				height: 40px;
				border-radius: 10px;
				background: linear-gradient(270deg, #F36932 0%, #E6200C 100%), #FFEFE9;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 18px;
				font-weight: 500;
				color: #fff;
			}
		}

		
	}






	::v-deep .el-tabs__item.is-active {
		color: red; //选中
		opacity: 1;
		font-weight: bold;
	}

	::v-deep .el-tabs__active-bar {
		height: 4px;
		background: #FF0000;
	}

	::v-deep .el-tabs__item {
		font-size: 20px;
	}

	::v-deep .el-tabs__item:hover {
		color: red; //悬浮
		cursor: pointer;
		opacity: 1;
	}

	::v-deep .el-tabs__nav-wrap::after {
		background-color: #F9E6E0
	}

	::v-deep .elbuttontext-white {
		color: #000;
	}

	.xian {
		height: 1px;
		background-color: #F2EAE8;
		margin: 40px 0;
	}
</style>