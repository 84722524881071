import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'; // 假设你的 store 文件是这样导入的

import Home from '@/view/home/index.vue'
import recommend from '@/view/vol/recommend'//推荐说明


import vol from '@/view/vol'
import alreadyVol from '@/view/vol/alreadyVol'
import volPrint from '@/view/vol/volPrint'

import aiVol from '@/view/aiVol'
import volTwo from '@/view/volTwo'
import volTwoAlreadyVol from '@/view/volTwo/volTwoAlreadyVol'
import volTwoPrint from '@/view/volTwo/volTwoPrint'

import schoolList from '@/view/school/SchoolList'
import schoolInfo from '@/view/school/SchoolInfo'
import schoolArticleDetail from '@/view/school/SchoolArticleDetail'

import specialList from '@/view/special/SpecialList'
import specialInfo from '@/view/special/SpecialInfo'

import articleList from '@/view/article/ArticleList'
import articleDetail from '@/view/article/ArticleDetail'

Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        component: Home
        // redirect: '/home'
    },
	
	// 推荐说明
	{
	    path: '/recommend',
	    component: recommend,
	},
	// 山东填报
	{
	    path: '/vol',
		name: 'collegeSpecial',
	    component: vol,
		// meta: { requiresAuth: true } // 需要登录才能访问
	},
	// 山东填报 已选
	{
	    path: '/alreadyVol',
		name: 'alreadyVol',
	    component: alreadyVol,
	},
	// 山东填报 打印
	{
	    path: '/volPrint',
	    component: volPrint,
	},
	
	
	
	// 另外一种填报方式
	{
	    path: '/volTwo',
		name: 'collegeSpecialGroup',
	    component: volTwo,
	},
	
	// 另外一种填报方式 已选
	{
	    path: '/volTwoAlreadyVol',
		name: 'volTwoAlreadyVol',
	    component: volTwoAlreadyVol,
	},
	// 另外一种填报方式 打印
	{
	    path: '/volTwoPrint',
	    component: volTwoPrint,
	},
	
	// AI填报
	{
	    path: '/aiVol',
		name: 'ai',
	    component: aiVol,
	},
	//院校库
	{
	    path: '/school/schoolList',
		name: 'schoolList',
	    component: schoolList,
	},
	{
	    path: '/school/schoolInfo',
		name: 'schoolInfo',
	    component: schoolInfo,
	},
	{
	    path: '/school/schoolArticleDetail',
		name: 'schoolArticleDetail',
	    component: schoolArticleDetail,
	},
	{
		path: '/special/specialList',
		name: 'specialList',
		component: specialList
	},
	{
		path: '/special/specialInfo',
		name: 'specialInfo',
		component: specialInfo
	},
	{
		path: '/article/articleList',
		name: 'articleList',
		component: articleList
	},
	{
		path: '/article/articleDetail',
		name: 'articleDetail',
		component: articleDetail
	},
    // {
    //     path: '/',
    //     component: Home
    // },
   




]
const router = new VueRouter({
    mode: 'history',
    routes
})
// 在这里添加路由守卫代码
router.beforeEach((to, from, next) => {
	// 假设你有一个 Vuex store 实例，并已经通过某种方式将其导入或定义
	// const store = ... // 你应该在这里导入或定义你的 Vuex store
	// 或者，如果你使用 localStorage 或其他方式存储登录状态
	// const isLoggedIn = localStorage.getItem('token') !== null;
  
	// 在这个例子中，我们假设你已经通过某种方式获取了 isLoggedIn 的值
	// 你可能需要从 Vuex store 或者 localStorage 中获取它
	const isLoggedIn = store.getters.isLoggedIn; // 假设 store 已经被导入或定义
	const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  
	if (requiresAuth && !isLoggedIn) {
	  next({
		path: '/home', // 重定向到登录页面
		query: { redirect: to.fullPath } // 保存原始路径，登录后重定向回去
	  });
	} else {
	  next();
	}
});
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}


export default router