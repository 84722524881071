<template>
  <div class="school-admission">
    <div v-if="data.length">
      <table class="professional_table">
        <tbody>
          <tr class="header">
            <th>专业名称</th>
            <th>层次</th>
            <th>学科门类</th>
            <th>专业类别</th>
            <th>学制</th>
            <th>操作</th>
          </tr>
          <tr class="body" v-for="(item,index) in data" :key="item.id">
            <td>{{ item.name }}</td>
            <td>{{ item.type_name }}</td>
            <td>{{ item.category1 }}</td>
            <td>{{ item.category2 }}</td>
            <td>{{ item.limit_year }}</td>
            <td class="detail" @click="toDetail(item.id)">查看详情</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="nodata" v-else>
        <img src="@/assets/img/no_data.svg">
    </div>
  </div>
</template>
  
<script>
  import { schoolSpecial } from '@/api/home';

export default {
  name: 'SchoolAdmission',
  props: {
    id: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      data: []
    };
  },
  methods: {
    async fetchschoolSpecial(id) {
      try {
        const response = await schoolSpecial({ id: id });
        if (response.code === 1) {
          this.data = response.data;
        } else {
          this.data = '';
        }
      } catch (error) {
        // 处理错误
      }
    },
    toDetail(id) {
      // 构建带有查询参数的路由路径
      const baseUrl = this.$router.resolve({ name: 'specialInfo' }).href;
      const queryParams = `?id=${encodeURIComponent(id)}`;
      const fullUrl = `${baseUrl}${queryParams}`;

      // 在新窗口打开带有查询参数的路径
      window.open(fullUrl, '_blank');
    }
  },
  mounted() {
    this.fetchschoolSpecial(this.id);
  }
};
</script>
<style lang="scss" scoped>
  .school-admission{
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    padding:50px 23px;
  }
  .professional_table{
    table-layout: fixed;
    box-shadow: 0 0 13px #e6e6e6;
    border-radius: 10px;
    overflow: hidden;
    border-collapse: collapse;
    width: 100%;
      .header{
        background: #fafafa;
        height: 44px;
        & > th{
          font-size: 14px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          vertical-align: middle;
          text-align: center;
          font-weight: 600;
          color: #000;
          border-right: 1px solid #ededed;
        }
      }
      .body{
        text-align: center;
        border: 1px solid #ededed;
        & > td{
          color: #666;
          padding: 15px 5px;
          vertical-align: middle;
          border-right: 1px solid #ededed;
          text-align: center;
          font-size: 14px;
        }
        .detail{
          color: #F15F2D;
          cursor: pointer;
        }
      }
  }
  .nodata{
      text-indent: 15px;
      color: #888;
      width: 100%;
      height: 80px;
      line-height: 80px!important;
      border: 1px solid #e4e4e4;
      text-align: center;
      font-size: 17px;
      font-weight: 200!important;
      img{
        width: 114px;
        aspect-ratio: auto 114 / 30;
        height: 30px;
        vertical-align: middle !important;
      }
    }
</style>
  