import {service,service2} from '@/utils/request'
// 验证码
export  function sendSms(data) {
  return service({
    url: '/api/xcxuser/sendSms',
    method: 'post',
    data:data
  })
}
export  function login(data) {
  return service({
    url: '/api/xcxuser/login',
    method: 'post',
    data:data
  })
}
export  function activation(data) {
  return service({
    url: '/api/xcxuser/activation',
    method: 'post',
    data:data
  })
}
export  function getCardInfo(data) {
  return service({
    url: '/api/xcxuser/getCardInfo',
    method: 'post',
    data:data
  })
}
export  function updateUserInfo(data) {
  return service({
    url: '/api/xcxuser/updateUserInfo',
    method: 'post',
    data:data
  })
}
export  function getBatch(data) {
  return service({
    url: '/api/xcxuser/getBatch',
    method: 'post',
    data:data
  })
}
export  function getDataList(data) {
  return service({
    url: '/api/xcxuser/getDataList',
    method: 'post',
    data:data
  })
}
export  function getDataGroupList(data) {
  return service({
    url: '/api/xcxuser/getDataGroupList',
    method: 'post',
    data:data
  })
}
export  function getSpecialGroupList(data) {
  return service({
    url: '/api/xcxuser/getSpecialGroupList',
    method: 'post',
    data:data
  })
}
export  function saveVolunteer(data) {
  return service({
    url: '/api/volunteer/saveVolunteer',
    method: 'post',
    data:data
  })
}
export  function getUserVolunteerList(data) {
  return service({
    url: '/api/volunteer/getUserVolunteerList',
    method: 'post',
    data:data
  })
}
export  function deleteUserVolunteer(data) {
  return service({
    url: '/api/volunteer/deleteUserVolunteer',
    method: 'post',
    data:data
  })
}
export  function getVolunteerList(data) {
  return service({
    url: '/api/volunteer/getVolunteerList',
    method: 'post',
    data:data
  })
}
export  function downloadExcel(data) {
  return service({
    url: '/api/volunteer/downloadExcel',
    method: 'post',
    data:data
  })
}
export  function aiVolunteer(data) {
  return service({
    url: '/api/volunteer/aiVolunteer',
    method: 'post',
    data:data
  })
}
/**
 * 功能：获取广告
 */
export const getAd = (data) => {
  return service({
      url: '/api/xcxindex/getAd',
      method: 'post',
      data: data
  });
};
/**
 * 功能：基本配置
 */
export const getBasicInfo = (data) => {
  return service({
      url: '/api/xcxindex/getBasicInfo',
      method: 'post',
      data: data
  });
};


/* service2 */
export  function getCondition(data) {
  return service2({
    url: '/api/xcxindex/getCondition',
    method: 'post',
    data:data
  })
}
export  function getAiInit(data) {
  return service2({
    url: '/api/volunteer/getAiInitPC',
    method: 'post',
    data:data
  })
}
/**
 * 功能：专业分类
 */
export const getSpecialCategory = (params) => {
  return service2({
      url: '/api/special/getSpecialCategory',
      method: 'get',
      params: params
  });
};
/**
* 功能：分类包含专业
*/
export const getCategorySpecial = (data) => {
  return service2({
      url: '/api/special/getCategorySpecial',
      method: 'post',
      data: data
  });
};
/**
* 功能：专业详情
*/
export const getSpecialDetail = (data) => {
  return service2({
      url: '/api/special/getSpecialDetail',
      method: 'post',
      data: data
  });
};
/**
* 功能：开设专业的院校筛选
*/
export const specialSchoolCondition = (data) => {
  return service2({
      url: '/api/special/getSchoolCondition',
      method: 'post',
      data: data
  });
};
/**
* 功能：专业的开设院校列表
*/
export const getSpecialSchool = (data) => {
  return service2({
      url: '/api/special/getSpecialSchool',
      method: 'post',
      data: data
  });
};
/**
* 功能：院校筛选条件
*/
export const getSchoolCondition = (params) => {
  return service2({
      url: '/api/school/getSchoolCondition',
      method: 'get',
      params: params
  });
};
/**
* 功能：院校列表
*/
export const getSchoolList = (data) => {
  return service2({
      url: '/api/school/getSchoolList',
      method: 'post',
      data: data
  });
};
/**
* 功能：院校推荐列表
*/
export const recommendList = (data) => {
  return service2({
      url: '/api/school/recommendList',
      method: 'post',
      data: data
  });
};
/**
* 功能：院校详情
*/
export const getSchoolDetail = (data) => {
  return service2({
      url: '/api/school/getSchoolDetail',
      method: 'post',
      data: data
  });
};
/**
* 功能：院校开设专业
*/
export const schoolSpecial = (data) => {
  return service2({
      url: '/api/school/schoolSpecial',
      method: 'post',
      data: data
  });
};
/**
* 功能：院校招生简章
*/
export const getSchoolEnrollment = (data) => {
  return service2({
      url: '/api/school/getSchoolEnrollment',
      method: 'post',
      data: data
  });
};
/**
 * 功能：文章详情
 */
export const getArticleDetail = (data) => {
  return service2({
      url: '/api/school/getEnrollmentDetail',
      method: 'post',
      data: data
  });
};
/**
 * 功能：热门资讯
 */
export const hotArticle = (data) => {
  return service2({
      url: '/api/article/hotArticle',
      method: 'post',
      data: data
  });
};
/**
 * 功能：文章列表
 */
export const articleList = (data) => {
  return service2({
      url: '/api/article/articleList',
      method: 'post',
      data: data
  });
};
/**
 * 功能：文章详情
 */
export const articleDetail = (data) => {
  return service2({
      url: '/api/article/articleDetail',
      method: 'post',
      data: data
  });
};
