<template>
  <div class="main">
    <header-image text="专业查询"></header-image>
    <div class="content">
      <div class="header">
        <div :class="item.id == lv0_id?'record-selected':''" v-for="(item,index) in lv0" :key="index" @click="seleced_lv0(item.id)">
          {{ item.name }}
        </div>
      </div>
      <div class="body">
        <div class="body-header flex">
          <div class="body-header-first cross-center">
            <p>门类</p>
          </div>
          <div class="body-header-second cross-center">
            <p>
              专业类
            </p>
          </div>
          <div class="body-header-three flex-between cross-center">
            <div><p><span>专业名称</span></p></div>
            <div><p><span>专业代码</span></p></div>
            <div><p><span>开设院校</span></p></div>
          </div>
        </div>
        <div class="body-content flex">
        <div class="body-content-first">
          <div class="el-scrollbar" style="height:735px;overflow-y: auto;">
            <template v-for="(item,index) in lv1_data">
              <p :class="['scrollbar-item',lv1_id === item.id?'category-selected':'']" @click="seleced_lv1(item.id)"><span>{{ item.name }}</span></p>
            </template>
          </div>
        </div>
        <div class="body-content-second">
          <div  class="el-scrollbar" style="height:735px;overflow-y: auto;">
            <template v-for="(item,index) in lv2_data">
              <p :class="['scrollbar-item',lv2_id === item.id?'category-selected':'']" @click="seleced_lv2(item.id)"><span>{{ item.name }}</span></p>
            </template>
          </div>
        </div>
        <div class="body-content-three">
          <div  class="el-scrollbar" style="height:735px;overflow-y: auto;">
            <div v-for="item in lv3_data" :key="item.id"  class="scrollbar-item"  @click="toInfo(item.id)">
              <p><span>{{ item.name }}</span></p>
              <p><span class="code">{{ item.code }}</span></p>
              <p><span class="select">查看</span></p>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>
<script>
 import { getSpecialCategory, getCategorySpecial } from '@/api/home';
import HeaderImage from '@/components/HeaderImage.vue';

export default {
  components: {
    HeaderImage
  },
  data() {
    return {
      lv0: [],
      lv1: [],
      lv2: [],
      lv1_data: [],
      lv2_data: [],
      lv3_data: [],
      lv0_id: null,
      lv1_id: null,
      lv2_id: null
    };
  },
  methods: {
    handleTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // 可选的，使用平滑滚动效果
      });
    },
    async fetchSpecialCategory() {
      try {
        const response = await getSpecialCategory();
        if (response.code === 1) {
          this.lv0 = response.data.lv0;
          this.lv1 = response.data.lv1;
          this.lv2 = response.data.lv2;
          this.lv0_id = this.lv0[0].id;
          this.seleced_lv0(this.lv0_id);
        }
      } catch (error) {
        // 处理错误
      }
    },
    seleced_lv0(id) {
      this.lv0_id = id;
      this.lv1_data = this.lv1.filter(item => item.parent_id === id);
      if(this.lv1_data.length === 0){
        this.lv2_id = '';
        this.lv3_id = '';
        this.lv2_data = [];
        this.lv3_data = [];
        return
      }
      this.seleced_lv1(this.lv1_data[0].id);
    },
    seleced_lv1(id) {
      this.lv1_id = id;
      this.lv2_data = this.lv2.filter(item => item.parent_id === id);
      this.seleced_lv2(this.lv2_data[0].id);
    },
    async seleced_lv2(id) {
      this.lv2_id = id;
      try {
        const response = await getCategorySpecial({ id: id });
        if (response.code === 1) {
          this.lv3_data = response.data;
        }
      } catch (error) {
        // 处理错误
      }
    },
    toInfo(id) {
      // 构建带有查询参数的路由路径
      const baseUrl = this.$router.resolve({ name: 'specialInfo' }).href;
      const queryParams = `?id=${encodeURIComponent(id)}`;
      const fullUrl = `${baseUrl}${queryParams}`;

      // 在新窗口打开带有查询参数的路径
      window.open(fullUrl, '_blank');
    }
  },
  mounted() {
    this.handleTop();
    this.fetchSpecialCategory();
  }
};
</script>
<!--声明语言，并且添加scoped-->
<style lang="scss" scoped>
  .main{
    background-color: #F5F5F5;
    padding: 0 0 40px;
  }
  .content{
    width: 1180px;
    margin: 0 auto;
    background-color: #fff;
    padding: 30px 40px 60px;
    box-sizing: border-box;
  }
  .header{
    display: flex;
    & > div{
      margin-right: 15px;
      padding: 10px 20px;
      font-size: 16px;
      line-height: 16px;
      color: #474848;
      border: 1px solid #F15F2D;
      border-radius: 3px;
      cursor: pointer;
    }
  }
  .record-selected{
    background: #F15F2D !important;
    color: #fff !important;
  }
  .body{
    margin-top: 26px;
    width: 100%;
    height: 790px;
    // border: 2px solid #E5E5E5;
    font-size: 16px;
    color: #494949;
    &-header{
      height: 55px;
      & > div{
        height: 100%;
        & > p{
          padding-left: 14px;
        }
      }
      &-first{
        @extend .border-bottom,.border-left,.border-top;
        box-sizing: border-box;
        width: 224px;
      }
      &-second{
        @extend .border-bottom,.border-left,.border-top;
        box-sizing: border-box;
        width: 236px;
      }
      &-three{
        @extend .border-bottom,.border-left,.border-top,.border-right;
        box-sizing: border-box;
        flex: 1;
        & > div{
          width: calc(100% / 3);
          box-sizing: border-box;
          & > p{
            padding-left: 14px;
          }
        }
      }
    }
    &-content{
      height: calc(790px - 55px);
      & > div{
        height: 100%;
      }
      &-first{
        width: 224px;
        @extend .border-bottom,.border-left;
        box-sizing: border-box;
        span{
          padding-left: 14px;
        }
      }
      &-second{
        width: 236px;
        @extend .border-bottom,.border-left;
        box-sizing: border-box;
        span{
          padding-left: 14px;
        }
      }
      &-three{
        flex: 1;
        @extend .border-left,.border-right,.border-bottom;
        box-sizing: border-box;
        p{
          width: calc(100% / 3);
          padding-left: 14px;
          box-sizing: border-box;
          .code,.select{
            display: block;
            width: 33%;
            text-align: center;
          }
        }
      }
    }
  }
  .scrollbar-item {
    width: 100%;
    display: flex;
    align-items: center;
    height: 61px;
    cursor: pointer;
  }
  .border-top{
    border-top: 2px solid #E5E5E5;
  }
  .border-right{
    border-right: 2px solid #E5E5E5;
  }
  .border-bottom{
    border-bottom: 2px solid #E5E5E5;
  }
  .border-left{
    border-left: 2px solid #E5E5E5;
  }
  .scrollbar-item:hover{
    background-color: #EEEEEE;
    color: #F15F2D;
  }
  .category-selected{
    background-color: #EEEEEE;
    color: #F15F2D;
    border-left: 4px solid #F15F2D;
  }
  .flex {
    display: flex
  }

  .flex-column {
      display: flex;
      flex-direction: column
  }

  .flex-center {
      display: flex;
      justify-content: center;
      align-items: center
  }

  .main-center {
      display: flex;
      justify-content: center
  }

  .cross-center {
      display: flex;
      align-items: center
  }

  .flex-between {
      display: flex;
      justify-content: space-between
  }
  
</style>