<template>
  <div class="main">
    <header-image text="院校查询"></header-image>
    <div class="content">
      <div class="content-left">
        <school-filter :data="condition_data" :condition="condition"  @returnData="return_condition"></school-filter>
        <school-list :data="dataList" :total="total" :pageSize="pageSize" :currentPage="currentPage" @returnCurrentPage="returnCurrentPage"></school-list>
      </div>
    </div>
  </div>
</template>
<script>
import { getSchoolCondition, getSchoolList, recommendList } from '@/api/home';
import SchoolFilter from '@/components/SchoolFilter.vue';
import SchoolList from '@/components/SchoolList.vue';
import HeaderImage from '@/components/HeaderImage.vue';

export default {
  components: {
    SchoolFilter,
    SchoolList,
    HeaderImage
  },
  data() {
    return {
      recomentList: [],
      dataList: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      condition_data: [],
      condition: {
        key: '',
        province_name: '',
        subject_name: '',
        nature_name: '',
        type_name: '',
        characteristic: ''
      },
      key: '',
      province_name: '',
      subject_name: '',
      nature_name: '',
      type_name: '',
      characteristic: ''
    };
  },
  watch: {
    '$route.query'(query) {
      if (query.characteristic) {
        this.condition.characteristic = query.characteristic;
        this.characteristic = query.characteristic;
      }
      this.currentPage = 1; // 重置当前页码为第一页
      this.fetchSchoolList();
    },
    // 注意：由于 Vue 2 中不能直接在 watch 中监听 route.query，你可能需要使用路由守卫或 Vue 3 的 Composition API 替代方案
    // Vue 3 中可以如上所示直接监听
  },
  methods: {
    handleTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // 可选的，使用平滑滚动效果
      });
    },
    fetchSchoolCondition() {
      getSchoolCondition().then(response => {
        if (response.code === 1) {
          this.condition_data = response.data;
        }
      }).catch(error => {
        // 处理错误
      });
    },
    async fetchSchoolList() {
      getSchoolList({
        key: this.key,
        province_name: this.province_name,
        subject_name: this.subject_name,
        nature_name: this.nature_name,
        type_name: this.type_name,
        characteristic: this.characteristic,
        page: this.currentPage,
        rows: this.pageSize
      }).then(response => {
        if (response.code === 1) {
          this.dataList = response.data.list;
          this.total = response.data.total;
        } else {
          this.dataList = [];
          this.total = 0;
        }
      }).catch(error => {
        // 处理错误
      });
    },
    return_condition(data) {
      this.condition = { ...data };
      this.key = data.key;
      this.province_name = data.province_name;
      this.subject_name = data.subject_name;
      this.nature_name = data.nature_name;
      this.type_name = data.type_name;
      this.characteristic = data.characteristic;
      this.currentPage = 1;
      this.fetchSchoolList();
    },
    returnCurrentPage(newPage) {
      this.currentPage = newPage;
      this.fetchSchoolList();
      // this.handleTop();
    }
  },
  mounted() {
    this.handleTop();
    this.fetchSchoolCondition();
    this.fetchSchoolList();
  }
};
</script>
<!--声明语言，并且添加scoped-->
<style lang="scss" scoped>
  .main{
    background-color: #F5F5F5;
    padding: 0 0 40px;
  }
  .content{
    width: 1180px;
    margin: 0 auto;
    display: flex;
    &-left{
      width: 100%;
      margin-right: 8px;
    }
    &-right{
      width: 332px;
      
    }
  }
  .bar{
    background-color: #FFF;
    padding: 41px 23px 27px;
    box-sizing: border-box;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 1px;
  }
</style>