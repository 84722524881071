<template>
  <div class="footer">
    <div class="footer-links">
      <ul class="links">
        <li v-for="(item,index) in data.friendly_links" :key="index">
          <a :href="item" target="_blank">
            {{ index }}
          </a>
        </li>
      </ul>
    </div>
    <div class="footer-about">
      <div class="us" v-show="data">
        <div class="us-logo">
          <img :src="data.bottom_logo" alt=""/>
          <p></p>
          <img :src="data.slogan_img" alt=""/>
        </div>
        <div class="us-tel">
          <p>{{data.contact_information}}</p>
        </div>
      </div>
      <div class="info">
        <ul class="info-links">
          <li v-for="(item,index) in data.local_links" :key="index">
            <a :href="item" target="_blank">
              {{ index }}
            </a>
          </li>
        </ul>
        <p>{{ data.unit }}</p>
        <p>{{ data.copyright }}</p>
        <p>{{ data.filing }}</p>
      </div>
      <div class="official" v-if="data">
        <div>
          <img :src="data.wechat_qrcode" alt="">
          <p>官方微信</p>
        </div>
        <div>
          <img :src="data.tiktok_qrcode" alt="">
          <p>官方抖音</p>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
  import { getBasicInfo } from '@/api/home';
  export default {
    name: 'Footer',
    props: {
    },
    data() {
      return {
        data:''
      }
    },
    mounted() {
      this.getBasicInfo();
    },
    methods: {
      async getBasicInfo() {
        try {
          const response = await getBasicInfo({}); // 传递需要的参数
          if(response.code == 1){
            this.data = response.data;
          }
        } catch (error) {

        }
      },
    }
  }
</script>
<style lang="scss" scoped>
  .footer{
    font-family: 'MicrosoftYaHei';
    background-color: #282828;
    &-links{
      height: 66px;
      font-size: 14px;
      line-height: 14px;
      border-bottom: 1px solid #525252;
    }
    &-about{
      width: 1180px;
      height: 230px;
      display: flex;
      justify-content: space-between;
      padding-top: 57.15px;
      box-sizing: border-box;
      margin: 0 auto;
    }
  }
  .links{
    width: 1180px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    li{
      cursor: pointer;
      a{
        color: #CFCECE;
      }
      &:hover{
        text-decoration: underline;
      }
    }
  }
  .us{
    margin-top: 11px;
    &-logo{
      display: flex;
      & > img{
          width: 169px;
          height: 55px;
          &:last-child{
            align-self: flex-end;
            width: 130px;
            height: 50px;
          }
      }
      & > p{
        width: 1px;
        height: 50px;
        background-color: #707070;
        margin:0 18px;
        align-self: center;
      }
    }
    &-tel{
      font-size: 14px;
      color: #CFCECE;
      margin-top: 12.56px;
      text-align: center;
    }
  }
  .info{
    color: #CFCECE;
    font-size: 14px;
    &-links{
      display: flex;
      & > li{
        margin-right: 28px;
        a{
          color: #CFCECE;
        }
        &:last-child{
          margin-right: 0;
        }
      }
    }
    p{
      margin-top: 13px;
    }
  }
  .official{
    display: flex;
    color: #CFCECE;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.18em;
    margin-top: 12.44px;
    & > div{
      display: flex;
      flex-direction: column;
      align-items: center;
      &:first-child{
        margin-right: 22px;
      }
      & > img{
        margin-bottom: 7px;
        width: 85px;
        height: 85px;
      }
    }
  }
</style>
  