<template>
  <div class="school-content">
    <!-- <div class="header">
      <h1>院校介绍</h1>
      <p>更新时间：{{ date }}</p>
    </div> -->
    <div class="bar">
      <div class="title">院校介绍</div>
    </div>
    <div v-html="data"></div>
  </div>
</template>
  
<script>
  export default {
    name: 'SchoolEmployment',
    components: {
    },
    props: {
      data:'',
      date:''
    },
    data() {
      return {
        
      }
    },
    mounted() {
      
    },
    methods: {
     
    }
  }
</script>
<style lang="scss" scoped>
  .school-content{
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 1px;
    font-size: 14px;
    padding: 50px 23px 105px;
    box-sizing: border-box;
    color: #666;
  }
  .header{
    display: flex;
    align-items: flex-end;
    & > h1{
      font-size: 28px;
      color: #5E677A;
      margin-right: 20px;
    }
  }
  .bar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .title{
      font-size: 20px;
      &::before{
        content: "";
        display: inline-block;
        width: 0;
        border-right: 3px solid #F15F2D;
        height: 18px;
        margin: 0 10px 0 0;
        border-radius: 20px;
        position: relative;
        top: 2px;
      }
    }
  }
</style>
  