<template>
	<div class="home_box">
		
			放图片

	</div>
</template>

<script>
	import * as echarts from 'echarts';
	import {
		lunbotu,
		jituanjianjie,
		shipinshouye,
		ywtx,
		ssfw,
		jjrongyu,
		qxpp,
		homeimage
	} from "@/api/home";
	export default {
		components: {},

		data() {
			return {
				rightShow: false, //右侧弹框展示与隐藏
				slidervalue: [600, 400],
				activeName: 'A',
				xuankeData: ['物理', '化学', '生物', '历史', '地理'],
				login: {
					radio: ''
				},
				form: {
					name: '',
					region: '',
					date1: '',
					date2: '',
					delivery: false,
					type: [],
					resource: '',
					desc: ''
				}
			};
		},
		created() {},
		mounted() {},
		methods: {
			formatTooltip() {
				this.$nextTick(() => {
					this.$refs.slider.setPosition(this.slidervalue)
				})
			},
			//点击右侧悬浮框
			pullleftClick() {
				this.rightShow = !this.rightShow
			},
			// 页面返回到顶部
			topClick() {
				const scrollToTop = setInterval(() => {
					const currentPosition = document.documentElement.scrollTop || document.body.scrollTop;
					if (currentPosition > 0) {
						window.requestAnimationFrame(() => {
							window.scrollTo(0, currentPosition - currentPosition / 8);
						});
					} else {
						clearInterval(scrollToTop);
					}
				}, 16); // 16ms 是一个合理的帧率间隔，可以保持动画的流畅
			}

		},
	};
</script>
<style scoped lang="scss" src="./index.scss" />
<style lang="scss" scoped>
	.home_box {
		min-height: 100vh;
		padding: 30px;
		max-width: 1940px;
		margin: 0 auto;
		background-image: linear-gradient(to bottom, #FFE7DF, #F9F9F9 80%);
		background-repeat: no-repeat;
		background-color: #F9F9F9 !important;
		// .home_top{
		// 	display: -webkit-box;
		// 	display: -webkit-flex;
		// 	display: -ms-flexbox;
		// 	display: flex;
		// 	-webkit-box-pack: justify;
		// 	-webkit-justify-content: space-between;
		// 	-ms-flex-pack: justify;
		// 	align-items: center;
		// 	.home_top_right{
		// 		width: 160px;
		// 		height: 40px;
		// 		border-radius: 10px;
		// 		background: linear-gradient(270deg, #F36932 0%, #E6200C 100%), #FFEFE9;
		// 		display: flex;
		// 		align-items: center;
		// 		justify-content: center;
		// 		font-size: 18px;
		// 		font-weight: 500;
		// 		color: #fff;
		// 	}
		// }
	}

	/deep/.el-slider__bar {
		background-color: #F0673A;
	}

	/deep/.el-slider__button {
		border: 2px solid #F0673A;
	}
	.buttom_div {
		   display: block;
		}
</style>