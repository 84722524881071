<template>
  <div class="sketch-list">
    <header-image text="热门资讯"></header-image>
    <div class="center">
      <div class="list">
        <ul class="list-main">
          <li class="list-main-item" v-for="(item,index) in dataList" :key="index" @click="goToArticleDetail(item.id)">
            <div class="left-img" v-if="item.image">
              <img :src="item.image" alt="">
            </div>
            <div class="right-info">
              <div class="right-info-title">
                {{ item.title }}
              </div>
              <div class="right-info-desc">
                {{ item.abstract }}
              </div>
              <div class="right-info-other">
                <span>{{ item.creattime }}</span>
                <span>{{ item.num }}次浏览</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="pagination">
      <el-pagination
        v-model:currentPage="currentPage"
        v-model:pageSize="pageSize"
        :small="small"
        :disabled="disabled"
        :background="background"
        prev-text="上一页"
        next-text="下一页"
        layout="prev, pager, next, jumper"
        :total="total"
        @current-change="handleCurrentPageChange"
      />
    </div>
  </div>
</template>
<script>
  import { articleList } from '@/api/home';
  import HeaderImage from '@/components/HeaderImage.vue';
export default {
  components: {
    // 如果你有子组件，请在这里注册
    HeaderImage
  },
  data() {
    return {
      swiper: [],
      category: '',
      dataList: [],
      currentPage: 1,
      pageSize: 10,
      small: false,
      disabled: false,
      background: false,
      total: 0
    };
  },
  created() {
    this.fetchInitialData();
  },
  watch: {
    '$route.params.category'(newVal) {
      this.category = newVal;
      this.currentPage = 1;
      this.fetchArticleList();
    }
  },
  methods: {
    goToArticleDetail(id) {
      // 注意：在 Vue 2 中，通常使用 query 而不是 params 来传递路由参数
      // 但如果你确实需要 params（例如，在命名视图或星号路由中），你可能需要不同的处理方式
      // 这里假设你使用的是 query
      const baseUrl = this.$router.resolve({ name: 'articleDetail' }).href;
      const queryParams = `?id=${encodeURIComponent(id)}`;
      const fullUrl = `${baseUrl}${queryParams}`;
      // 在新窗口打开带有查询参数的路径
      window.open(fullUrl, '_blank');
    },
   
    handleTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // 可选的，使用平滑滚动效果
      });
    },
    async fetchArticleList() {
      try {
        const response = await articleList({ category_id: 9, page: this.currentPage, rows: this.pageSize });
        if (response.code === 1) {
          this.dataList = response.data.list;
          this.total = response.data.total;
        }
      } catch (error) {
        // 处理错误
      }
    },
    fetchInitialData() {
      // 假设你的路由已经包含了 category 参数
      this.category = this.$route.params.category; // 注意：这里应该是 $route.params 或 $route.query，取决于你的路由配置
      this.handleTop();
      this.fetchArticleList();
    },
    handleCurrentPageChange(newPage) {
      this.currentPage = newPage;
      this.fetchArticleList();
      this.handleTop();
    }
  }
};
</script>
<!--声明语言，并且添加scoped-->
<style lang="scss" scoped>
  .sketch-list{
    width: 100%;
  }
  .center{
    width: 1180px;
    margin:29px auto;
    display: flex;
    justify-content: space-between;
  }
  .list{
    width: 100%;
    &-main{
      margin-top: 21.5px;
      &-item{
        max-height: 155px;
        display: flex;
        cursor: pointer;
        margin-bottom: 35px;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
  .left-img{
    height: 100%;
    width: 205px;
    margin-right: 20px;
    img{
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }
  .right-info{
    flex: 1;
    display: flex;
    flex-direction: column;
    &-title{
      font-size: 22px;
      font-weight: normal;
      line-height: 22px;
      letter-spacing: 0em;
      color: #343434;
    }
    &-desc{
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 0em;
      color: #5D5D5D;
      margin-top: 9px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3; /* 设置最大行数 */
      overflow: hidden;
    }
    &-other{
      margin-top: auto;
      font-size: 12px;
      font-weight: normal;
      line-height: 12px;
      letter-spacing: 0em;
      color: #5D5D5D;
      span:last-child::before{
        content: '|';
        margin: 0 9px;
      }
    }
  }
  .ad{
    width: 305px;
  }
  .swiper-item{
    height: 100%;
    margin: 0 auto;
    position: relative;
    left: 0;
    top: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .pagination{
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 53px;
  }
</style>