<template>
  <div class="school-info">
   <div class="header">
    <div class="header-img" v-if="info.name">
      <school-carousel :images="info.images"></school-carousel>
    </div>
    <div class="header-right">
      <ul class="header-right-rank" v-if="info.rank">
        <li v-for="(item,index) in info.rank" :key="index">
          <div>{{ item.rank }}</div>
          <div>{{ item.name }}</div>
        </li>
      </ul>
      <div class="header-right-desc" v-if="info.content_desc">
        <span>{{info.content_desc + '...'}}</span><span @click="more">更多></span>
       </div>
    </div>
   </div>
   <div class="content">
    <div class="content-left">
      <div class="basic-info">
        <div class="bar">
          <div class="title">基本信息</div>
          <!-- <span class="more">更多></span> -->
        </div>
        <div class="info-box">
          <div class="info-box-top" v-if="info.num_lab || info.num_lab2 || info.num_master || info.num_master2 || info.num_subject || info.gbh_num">
            
            <div class="info-box-top-item" v-if="info.num_lab || info.num_lab2">
              <div class="top_doctor">
                <div>
                  <p class="doctor-one">
                    <span>{{ info.num_lab??0 }}</span>
                    <span style="display: inline-block;">个</span>
                  </p>
                  <p>一级</p>
                </div>
                <i>/</i>
                <div>
                  <p class="doctor-two">
                    <span>{{ info.num_lab2??0 }}</span>
                    <span style="display: inline-block;">个</span>
                  </p>
                  <p>二级</p>
                </div>
              </div>
              <p class="bottom-title bottom-title01">
                <i></i>博士点
              </p>
            </div>

            <div class="info-box-top-item" v-if="info.num_master || info.num_master2">
              <div class="top_doctor">
                <div>
                  <p class="doctor-one">
                    <span>{{ info.num_master??0 }}</span>
                    <span style="display: inline-block;">个</span>
                  </p>
                  <p>一级</p>
                </div>
                <i>/</i>
                <div>
                  <p class="doctor-two">
                    <span>{{ info.num_master2??0 }}</span>
                    <span style="display: inline-block;">个</span>
                  </p>
                  <p>二级</p>
                </div>
              </div>
              <p class="bottom-title bottom-title02">
                <i></i>硕士点
              </p>
            </div>

            <div class="info-box-top-item" v-if="info.num_subject">
              <h3 class="top-num">
                {{ info.num_subject }}<span style="display: inline-block;">个</span>
              </h3>
              <p class="bottom-title bottom-title03">
                <i></i>国家重点学科
              </p>
            </div>

            <div class="info-box-top-item" v-if="info.gbh_num">
              <h3 class="top-num">
                {{ info.gbh_num }}<span style="display: inline-block;">个</span>
              </h3>
              <p class="bottom-title bottom-title04">
                <i></i>科研项目
              </p>
            </div>

          </div>
          <div class="info-box-bottom">
            <ul>
              <li>
                <i class="year"></i>
                <span style="width: 170px;">创建时间：{{ info.create_date?info.create_date+'年':'-' }}</span>
                <i class="area"></i>
                <span>占地面积（亩）：{{ info.area??'-' }}</span>
              </li>
              <li>
                <i class="department"></i>
                <span style="width: 450px;">主管部门：{{ info.belong??'-' }}</span>
              </li>
              <li>
                <i class="address"></i>
                <span style="word-break: break-all;white-space: normal;width: 95%;">学校地址：{{ info.address??'-' }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="photo-wall">
        <div class="bar">
          <div class="title">校园风光</div>
          <!-- <span class="more">更多></span> -->
        </div>
        <div class="photo-box">
          <ul>
            <li v-for="(item,index) in info.images" :key="index">
              <el-image
                style="width:100%;height:100%;border-radius: 4px;"
                :src="item"
                :zoom-rate="1.2"
                :preview-src-list="info.images"
                :initial-index="0"
                fit="cover"
              />
            </li>
          </ul>
        </div>
      </div>
      <div class="living-conditions">
        <div class="bar">
          <div class="title">食宿条件</div>
          <!-- <span class="more">更多></span> -->
        </div>
        <div class="conditions-box">
          <div class="conditions-box-overview">
            <div v-for="(item,index) in info.dormitory_canteen" :key="index">
              <div>
                <img :src="item.url" alt="">
              </div>
              <p>{{ item.name }}</p>
            </div>
          </div>
          <div class="conditions-box-detail">
            <div>
              <div class="detail-title">
                <i class="icon-dormitory"></i>宿舍
              </div>
              <div v-html="info.dormitory" style="white-space: pre-wrap;">
                
              </div>
            </div>
            <div>
              <div class="detail-title">
                <i class="icon-canteen"></i>食堂
              </div>
              <div v-html="info.canteen" style="white-space: pre-wrap;">
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="video">
        <div class="bar">
          <div class="title">校园视频</div>
          <!-- <span class="more">更多></span> -->
        </div>
        <div class="video-content">
          <div class="nodata">
              <img src="@/assets/img/no_data.svg">
          </div>
        </div>
      </div>
    </div>
    <div class="content-right">
      <div class="sex-ratio">
        <div class="bar">
          <div class="title">男女比例</div>
        </div>
        <div class="sex-ratio-content">
          <div style="display: flex;justify-content: center;align-items: center;">
            <div>
              <img src="https://www.gaokao.cn/static/media/nan.5a19d148.svg" width="20" height="50" alt="">
              <p>男</p>
            </div>
            <div class="rate-box">
              <div class="rate-box-num">
                <span>{{info.male}}%</span>
                <span>{{info.female}}%</span>
              </div>
              <div class="rate-box-line">
                <div class="line1" :style="{'width':info.male-1+'%'}"></div>
                <div class="line-center"></div>
                <div class="line2" :style="{'width':info.female-1+'%'}"></div>
              </div>
            </div>
            <div>
              <img src="https://www.gaokao.cn/static/media/nv.18ff3992.svg" width="20" height="50" alt="">
              <p>女</p>
            </div>
          </div>
        </div>
      </div>
      <div class="job-census">
        <div class="bar">
          <div class="title">毕业去向落实率</div>
        </div>
        <div class="job-census-content">
          <div class="job-item" v-for="(item,index) in info.destination_percentage" :key="index">
            <div class="job-circle" :class="'job-circle'+(index+1)">{{ item.rate }}</div>
            <div class="job-title">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <!-- <sidebar></sidebar> -->
    </div>
   </div>
  </div>
</template>
  
<script>
  // import Sidebar from './Sidebar.vue';
  import SchoolCarousel from './SchoolCarousel.vue';
  export default {
    name: 'SchoolInfo',
    components: {
      // Sidebar,
      SchoolCarousel
    },
    props: {
      info:{
        type:Object
      }
    },
    data() {
      return {
        data:'',
        // images: [
        //   'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
        //   'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg',
        //   'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',
        //   'https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg',
        //   'https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg',
        //   'https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg',
        // ]
      }
    },
    mounted() {
    },
    methods: {
      more(){
        this.$emit('active');
      }
    }
  }
</script>
<style lang="scss" scoped>
  .school-info{
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 1px;
    font-size: 14px;
  }
  .header{
    width: 1160px;
    height: 300px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-img{
      width: 450px;
      height: 250px;
      border-radius: 15px;
      background-color: #f0f0f0;
      & > img{
        width: 100%;
        height: 100%;
        border-radius: 20px;
      }
    }
    &-right{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      height: 250px;
      margin-left: 20px;
      &-rank{
        display: flex;
        & > li{
          width: 88px;
          height: 100px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-left: 15px;
          border-radius: 8px;
          box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
          &:first-child{
            margin-left: 0;
          }
          & > div{
            &:first-child{
              color: #F9635A;
              font-size: 20px;
              line-height: 20px;
              &::after{
                content: '名';
                color: #F9635A;
                font-size: 15px;
                line-height: 16px;
                margin-left: 1px;
              }
            }
            &:last-child{
              text-align: center;
              font-size: 15px;
              line-height: 16px;
              color: #666;
              margin-top: 10px;
              padding: 0 4px;
            }
          }
        }
      }
      &-desc{
        // width: 100%;
        // height: 130px;
        // border-radius: 8px;
        // padding: 10px 20px;
        box-sizing: border-box;
        padding: 15px;
        height: 135px;
        color: #000;
        box-shadow: 0 4px 15px 0 rgba(0,0,0,.1);
        border-radius: 8px;
        line-height: 25px;
        & > span{
          line-height: 28px;
          &:last-child{
            color: #FF6600;
            cursor: pointer;
          }
        }
      }
    }
  }
  .content{
    display: flex;
    justify-content: space-between;
    &-left{
      margin: 0 20px;
      width: 740px;
      float: left;
    }
  }
  .basic-info{
    margin-bottom: 23px;
  }
  .bar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .title{
      font-size: 20px;
      color: #000;
      &::before{
        content: "";
        display: inline-block;
        width: 0;
        border-right: 2px solid #F15F2D;
        height: 16px;
        margin: 0 10px 0 0;
        border-radius: 20px;
        position: relative;
        top: 2px;
      }
    }
    .more{
      cursor: pointer;
      color: #000;
      transition: all .4s;
    }

  }
  .info-box{
    &-top{
      margin-bottom: 20px;
      height: 100px;
      &-item{
        box-shadow: 0 0 13px #e6e6e6;
        float: left;
        width: 23%;
        height: 100px;
        margin-right: 2.666%;
        text-align: center;
        padding: 15px 20px;
        box-sizing: border-box;
        border-radius: 10px;
        position: relative;
        &:last-child{
          margin-right: 0 !important;
        }
        .top_doctor{
          display: flex;
          & > i{
              color: #999;
              padding: 5px 10px 0;
          }
          & > div > p:last-child{
            font-size: 12px;
            -webkit-transform: scale(.9);
            transform: scale(.9);
            text-align: left;
            color: #999;
          }
          .doctor-one{
            height: 25px;
            line-height: 25px;
            text-align: left;
            color: #333;
            & > span:first-child{
              font-size: 22px;
              margin-right: 3px;
            }
            & > span:last-child{
              font-size: 12px;
              -webkit-transform: scale(.9);
              transform: scale(.9);
            }
          }
          .doctor-two{
            height: 25px;
            line-height: 30px;
            text-align: left;
            color: #333;
            & > span:first-child{
              font-size: 16px;
              margin-right: 3px;
            }

            & > span:last-child{
              font-size: 12px;
              -webkit-transform: scale(.9);
              transform: scale(.9);
            }
          }
        }
        .top-num{
          font-size: 22px;
          line-height: 30px;
          margin-bottom: 5px;
          text-align: left;
          font-weight: 500;
          & > span{
            font-size: 12px;
          }
        }
        .bottom-title{
          font-size: 14px;
          color: #000;
          display: flex;
          align-items: center;
          position: absolute;
          bottom: 15px;
          i{
                margin-right: 10px;
                display: inline-block;
                width: 22px;
                height: 22px;
                vertical-align: middle;
                background-size: cover!important;
          }
        }
        .bottom-title01 > i{
          background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAsCAYAAAAehFoBAAADxElEQVRYR+2ZTUhUURTHf1fTNMVcNKMS2IeU6GAUZCqRgYQQtggKKkgiUcO+DVyUCC1aW5ARifSxiAzatIqEDJKgWhQVJhXkRyGmZZkS5deN4/XRqKPMm2G+qAMu3vPed373zP+ec967ilmmU1NdTEyUo1QxkI7WibPHBPRaqRGgB61biI5uUn197e7+lHWhXa5YBgbq0boKiAoolPcPn0Spyzgcp1R7+6hMmwKehr2H1kXePyuII5VqxeHYLtAG2OlsQOsjQUSw70qpS6q//6ia1uyrMJLBfIuZJDp6ndIOx3ngpP0lh2TGBaWdzna0zg6Je7tOlXojwMNBT112Qa3xSo2IJLSv80Mx7z9woKP+D0TY6YTsbPj1C548WTig+fkQFwevX8PXr2ZsZia8ewfat61jP8I7d0JjI/T2wvr1CwO/fAlpaVBaCvfvm78NG6CuDq5c8Uk9/gMnJcHy5Z6d37kDDgecOQOPH8OWLXDuHPz8CXv3wtAQdHTYAvcfeM8euHjRltMZg1NSbMnDf+CCAigr8wxcXAzx8fD0KfT1eR5TUWFrsf4DL+RutoZtoXke7D1wTAwkJkJJCdTXw+fPUFgIv39DZSUcPjzXw9KloBSMjMD4+Mz/i35zc20vwXvgHTvg6tW5Dm7dMj93dbVZRFfX3zGSEWJj4f17GBw095csgZwc+P4d1q4NMXBTk8kIlnmSxJo1JmMEHFjS14oVUFQEtbUwMGBS07dvJs9KhL98MfnZsqwsECl1dsLwsLm7eLEpHgEHtiA8FY7Tpw3wp0+milm2ebMBlEonCxRLSIC8vDABDitJeBNh2Viy8SwTvS5aBB8/mkxhSWL16jCJcE/PzFK7datpfl68gP5+AyypUaQSFhqeLYlHj0BKb1UVtLaCLECifugQdHfD7t0BTGsLSSI5GSSLyM9u5VtPKM+ewcqVcPw4NDfbhpUJ3heO+YAfPgSXyyfnU5P274eWFq/n+w988yZkZBiHy5aZXDyfpaebTSgpzsrLNTXQ1hZEYMvVtm1w4wZcvw5nz8LY2FwI6dpWrYJjx+D2ba8h3Qf6H2HraSdOmLIszc7z51BebgqJZXL/7VsQvYcFsIBJg9TQYKqblGt52xCTTm3TJjhwwFzv2wcPHoQ4wpZ76dBE0/J6dPcuSIPvbpJ/N26EHz/CBNgd4+BB2LULRkfNe9yHD3DtmmmGfDT7Go6KMj2uvKZL8x5ki8CPgRH3uTXiPmibY67IOTKQPRNRhzJTwOaMLnKOvdygI+Ng0T2VhvvR7R9FQO78DB51qgAAAABJRU5ErkJggg==);
        }
        .bottom-title02 > i{
          background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAsCAYAAAAehFoBAAAEGUlEQVRYR+2ZfUyVVRjAf+cityzNqOssqQtWBtPCTaZ8WG5k2VKyhpvYbH6kQAozhn9UmI3SPmzNrzLJSCsrtY9lfuRMZaF2+UMUwyVoUEEBLUQIKULafdvx9HaB62Xve7f7yrt5/nvPfZ7z/M5zn/Oc55wj6N2WaaPxsgCNyQjcaAzykwllh6AdjToEX+OgiOXi++7mxP8fBZqTC6xCsBANRyiZDI8t8KKxASd5FIgLUk8BS9gu9qJxn+HBrBQUFBPOQxJaAedrbwLZVjIEYWs9L4scgYrZin4TBoFmIsPDQZxgqbYajdwgZmy9imCNIF+Tq3CU9daDsnhKevi85akrKNaLKaJdelgLVv9y6F0BDrXXr3jYVh4e4IC4m8whlzeAmVVvKiQ2pUHs0MBAaR9B47PmgJ3LoMtrXMcUcNkiiI8MPPjNr0DDMz1/F756kEsl0KueDyHwvVEw5Gp4cCTkJEFdK2Tv9AHuPu0/ma3pMDMOSusg+W3jngxcUgSxcXw8Ax4bA2+UwuLdfUNU5ULMUFjrgdw9lwE4TEDTUogYCA9sggM1PSEmRsNdw1SfjIZ1qeBwwNbv4EhtT9kD1XCm2dwkTMWwHFoClWRA29/gesk//t6aBgsTjEHM/hS2nDAmq0uZBl47FRYnw/F6WPGNz9jPLVDeCDrwL61QdfbSMONvUWsh5MDDB0P1EhgY7g9SdBQydsCGafBkAqzzwFMBYrY0CxLdMOcz+KA8hB4ufASyxisDFb9BdTNEXw9jIyEQ8LhI2DsXOrrg1teUriXAd9wAlbkwIEwZXfIVrPoW5sdDUVpgYJkKD2VCeycMftFC4Flj4MMZvr/PKHDKCCheAK0dELHCQmD3EDiTB2X1MCHKuIdTY2DXbKhtgejXLQSWptLvhkm3Q8Y4yNsDqz3wRDy8mwbvHIXMHb4soW8Uc8fC5ungqYUJGxWwJwuS3BZkCWls46PmgJffD8+lwCcnIX2bDYAPZ8A90XC6Caa8Dz+29DMP5yTC1Bj4shJO/a52Rb21dMDMbXCnC+Sa2H4SjjWEMA93D4lzf0FbJwxygutaXwzr5mNdcHA+DL8OSn4C1zUwehh4vZC/H1YeMgca9Nasx3Bvc/qik6cO6eWCSWr7rWqCpEIu1sJfPA4ptynN7RUw73Po+MccuOlaQgdeWQKbj/uMOQSkxkJ2AkRFqP6zf0JiIdScU9/OMHhvuipNZTtWDw9vgcbzxqFNA786WRXksvApKvMZSnbDkUzQTxi7KmHRTvi1rSeMLDnX/FdA1TRD/Hr4ozOEwH0NLc98I2+EF4r96+Teek9PhH0/wIlG47BS0rSH+xo+3GHufGYOVUnb8jLQdtetNrvQtt2TgYxkWz3KSGDbPXvp0LZ5WOyeEPv50+2/q+fpMlJ9YsUAAAAASUVORK5CYII=);
        }
        .bottom-title03 > i{
          background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAsCAYAAAAehFoBAAADT0lEQVRYR+2ZWUiUURSAvztOY9bQvjDtFJWpGLSvkBJmtEG+FEUEhVLTgw60PVgG0b6oNFYk9BKVPdmTEDgPQURFUIItSEULZEaROpGJzh/XqzRUM3P/mWYDz9vPf+4533/+e869517BH2I4bZl0+XaCLw+YhIH9T52oPgu8wDuw3MFqqRbuzkZ/f6LvwSjLtPHxxTkwdmFgiSqUrnGBD8RFHOkuUdbYKYf1APfC1mEYubq2YqonhAdH+moJrYCLUi5gGM6YQph1JoRbXO7eI9Sc7WpImGkQ6EPk9LBas4VRZD2P4Ss2+8Fx0ReWcmEUWRoxyIgLgFmngmcSuD3mpcssaJ++wCuMQouhPd6WBuvKIHstjJkBFhPV7+1jcK+H1mZtd/9S1AeWsAcfwLjM8B02v4RzuRFB6wNvPA6r9kHbJ6gpBhkxwxccftg42HtX6bR+hKEOiBBaH/jQUxifBdVb4NFNvSjbR8HZT0r3xBIorIEREyOC1geu6oCUAXA4QznUlYpWGGiHyjXQ/BxcHhg1JWxoE8A/IcUKpenQ0qSLCzuuwYLN8Oo+nF4OwyeAqx5GTwsLOvrAcgocaoC0IfDwBlx3QupgBT12hoI+tQy+f9UKQvSBJcbCrbD9qiqD3i/w6h78aINFWxVkTQl4KhMIWKJMXaygx07/G6y2FOqOJRiwxLGmQlY+TJ4H9pEwa6WayzEFttpgZq6qBMHk/dO/k7UvIWMKLBcTuaiEkg4vlIwAX/dvzbgAT5kPBadU5gcTmWgyufwlLsChIhvsfT+wf3Sqwlzp+iOsu4EPFOGMPNh2BWwaSefekABJl7MHNlWETr22Ftg/IQHKmkSVm5jUEAuH3OF1tCdAhEPHNrBGVMta+VdIGwpH58L7J5Fg/h67uxZmr4NbLqjXmFbybE27a5b715krlGHpIFLx3yefyYGm3t4vhF19YNnaO28rcy88ek1oIOeDhsGcApA935uHcHJp6Ia215Y+sByQfwDWH1Gt0v+QDw1wqQA+v9a2Zg5YmnXMAll7ZWUQJg5S/JF+fFN/6EktdHdpw0pF88CmzP9/5SQETrrDwOQ7bk22A+1kuzKQeZxUlzI9wOqOLnmuvfygk+Ni0b+8J/rV7S/2Mcm8bgdKrAAAAABJRU5ErkJggg==);
        }
        .bottom-title04 > i{
          background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAsCAYAAAAehFoBAAADT0lEQVRYR+2ZWUiUURSAvztOY9bQvjDtFJWpGLSvkBJmtEG+FEUEhVLTgw60PVgG0b6oNFYk9BKVPdmTEDgPQURFUIItSEULZEaROpGJzh/XqzRUM3P/mWYDz9vPf+4533/+e869517BH2I4bZl0+XaCLw+YhIH9T52oPgu8wDuw3MFqqRbuzkZ/f6LvwSjLtPHxxTkwdmFgiSqUrnGBD8RFHOkuUdbYKYf1APfC1mEYubq2YqonhAdH+moJrYCLUi5gGM6YQph1JoRbXO7eI9Sc7WpImGkQ6EPk9LBas4VRZD2P4Ss2+8Fx0ReWcmEUWRoxyIgLgFmngmcSuD3mpcssaJ++wCuMQouhPd6WBuvKIHstjJkBFhPV7+1jcK+H1mZtd/9S1AeWsAcfwLjM8B02v4RzuRFB6wNvPA6r9kHbJ6gpBhkxwxccftg42HtX6bR+hKEOiBBaH/jQUxifBdVb4NFNvSjbR8HZT0r3xBIorIEREyOC1geu6oCUAXA4QznUlYpWGGiHyjXQ/BxcHhg1JWxoE8A/IcUKpenQ0qSLCzuuwYLN8Oo+nF4OwyeAqx5GTwsLOvrAcgocaoC0IfDwBlx3QupgBT12hoI+tQy+f9UKQvSBJcbCrbD9qiqD3i/w6h78aINFWxVkTQl4KhMIWKJMXaygx07/G6y2FOqOJRiwxLGmQlY+TJ4H9pEwa6WayzEFttpgZq6qBMHk/dO/k7UvIWMKLBcTuaiEkg4vlIwAX/dvzbgAT5kPBadU5gcTmWgyufwlLsChIhvsfT+wf3Sqwlzp+iOsu4EPFOGMPNh2BWwaSefekABJl7MHNlWETr22Ftg/IQHKmkSVm5jUEAuH3OF1tCdAhEPHNrBGVMta+VdIGwpH58L7J5Fg/h67uxZmr4NbLqjXmFbybE27a5b715krlGHpIFLx3yefyYGm3t4vhF19YNnaO28rcy88ek1oIOeDhsGcApA935uHcHJp6Ia215Y+sByQfwDWH1Gt0v+QDw1wqQA+v9a2Zg5YmnXMAll7ZWUQJg5S/JF+fFN/6EktdHdpw0pF88CmzP9/5SQETrrDwOQ7bk22A+1kuzKQeZxUlzI9wOqOLnmuvfygk+Ni0b+8J/rV7S/2Mcm8bgdKrAAAAABJRU5ErkJggg==);
        }
      }
    }
    &-bottom{
      padding: 25px;
      border-radius: 10px;
      box-shadow: 0 0 13px #e6e6e6;
      & > ul{
        font-size: 14px;
        font-weight: 400;
        color: #888;
        margin-bottom: 0;
        & > li{
          margin: 0 30px 30px 0;
          height: 18px;
          line-height: 18px;
          display: flex;
          align-items: center;
          i{
            display: inline-block;
            width: 18px;
            height: 18px;
            margin-right: 15px;
            position: relative;
            background-size: 100% 100%!important;
          }
         
          span{
              display: inline-block;
              width: 275px;
              font-size: 14px;
              color: #000;
              line-height: 18px;
          }
          &:last-child{
            margin: 0;
          }
          .year{
            background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAACrElEQVRYR82YPWgUQRTHf48oB6eSJkgOTCFqGo2doIVKGlHBxt7GOw9MUBArPyIhGkstgicIe42NnYX40Ug0jYVYKYIJqRRjITY5A5HknuzHfe1tbmf2Fu622uK9N7/5vzczb0bos0/6jAdrIL1ChjXGgXPAGMowQs6bmLKCsAJ8AV6QZV7mWLeZtDGQFsmxyRRwAWWn0SBCBXhKhhkp8cvEJxYoUMQFuYaSNQnaZiOsAQ/JcjdOsY5AOsEw6zxHOZoIJOwkfCDD+U5qbQmkBQ6jvETZkwpMLYjwgwHOyhM+R8WNBAqU+Zg6TDNUhiNRSrUBBTXzLrU0bSWvm74s4+GaagcqMEuVm8ZpEv6gZIAdxj4NpWbF4XazXwtQkKpl49UkrDLACDDEBktgua+5qy/DvubUtQLlKaFctpjpVylz0NsT8/w1nkirJI/FYaIhWvAX1M5v403P90sDqEKWoVot1RXSPKdRXluokw6QG0U4Iw5v/N+aQnkeoQ3pDMG6V8inKInDZBjoPcoJQ5CaWVpAC+JwshXoIovAgZ4AwZKUGQ0rtGpZ0GnWUEUcdnUPJCyLw35v2RfIU+U+sNtSZbeGIoCSpcwN9oARbsg0/7TIIBtMIVxF2W4BFpmyBZTjFkEapsI3YFIc3npqXWKUKnMop4ziCRFFnWzZt44nPEO5LmV+6jTb+M4iyt5YqC2WfZKNsX0s93yDGZRPiNfcDRoARWyMfvNue3TEjhVr4PbdUUeHl3v7wzV2vFgDIfpw9YD8Htq8/YgdLcYgrv0IVLqHcqvbsYz8hc4Nmgfk19I8yjGjoEmNTFvYptT1R5Nfm7AWGWOTV6nfPJJcg+pQ/XRRrEP5NdUfV+nmeg22hDs9f2wIL6LQc8whlFxPnmOSrm5bv9jnGNuA3dr/B+m6FjQK0kN/AAAAAElFTkSuQmCC");
          }
          .area{
            margin-left: 50px;
            background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAEd0lEQVRYR82XbYhUVRjHf8+dmUXbsuhFLd1At+31Q7lrb5a7e8+1XRMCDQKTtC8FFVKEBkFkK4VgiVBRfgosMwkJExF8mXuvK6hQ4Wwr5EtlWbRQaLFmKO7MPHHvuHfm7ow0uXfBAxdmzsv//zvPc8655wqXWZHLjIfaQJ7dBrIMdAYqt4JadYMLazH+srC/PpHCOzUAOhFkEOhHZAfStBZ7/blamtVAnr0aleX/C6JSWfgZ40+Lqjz7XZQX4+ZynIwsoN3tHwkVB/LNUor6ft3RuFjHjLTR7h0Mm/c491IoflXVNQBvtGZyv3sqPp/hf/u6JnIu/ytow6iBhFUY/7VIxzXHQFtqQL2H8V+qDeQ7T1Msrh81TCAgHMH4d5TT5qxAiyurtWUA405FRIfbyinznI/R4pJEgAKRjHUX7e53oZ7ffQvF89/X1K7sF85luHi2j9KZGBDyOo73VoX+AZQHqtOWmo/Jbq0RIXME1dsSAxL6MP6MSO9iG0asZRh3bQ0g+zTKVYkBBULpzHQ6dv0Uau599Aby5wZQ0jEPkXUY74U40LddjZwcOpMoTCgmr+B4a8ppM9tRnTcCaBfG644D9T7SQj5/bAyADuB4syJd1zwJ+lncR47jeM1xINe0g/YmDiQoDempPLx7INT+5rErOH3md5QrIy8hj3SMx+7JhzENG3xnIcXipsSBAkHLWortflCRtk9QXRzzshpasHf+UAby7JdRopWeLJj4OJ4pA83pRgs74usoNReT3VkRIfMORV2eLEi0KgpYjZOxt50Ma0o3gN9AJ5V6yClSPEunt6UiQmYjqovGBihM2zPY7keRvu/MDX9nrP5ofV1oLK0h13igdnJAUgCOEhyOSo60tZOO7KF69EtAnn0Y5fZ6BlT1Ec6icgjRHGLl0FQf467uZ9bms5eiNxyhQdAJ/y0gf4bGKn1YkiNFjtnXHkU2BxFJpEh4Ngye+ad65nICtA+RHKo5Mqk+Zmd/ScQ1fI0MtYK2lR7amGDdzczsoNDbNY1CfluY65J5Hw3jc8ze/lci5v68yejZ1tC09LSiNFVpp1N30pE9nOxXx34zhfO0UrhgHALoTXVNTNJzMLvdSwfq7WqiWCiFXMNZB78vnC11IYzoJEtwvA31A7mmC5FOKJbCr1x/KbYXHWPJq9je6jiQ9lj4vS2otDL+ui9jW9c1W0DnJwpRKSaEF35hj1lAAYOEH4X3gDaG/dKpVjqyuWiMa3pA3xg7INmE8RYJrvkRdHqVkWUtxHY/j+o9+3GUL8YMCPkUx1ssePbfsfvJsKOwAuO/WRGhZtDwijAmJVpDrgkOwJtrvBI2YvynonpVwTfBvbt8uUqMLHz3NeN4J4KUbQAtG0cm8jWOd1/M0zX7QR9MjKOcjehLNzipmygMHazexjKI410zAmgd6HOJAQXXV5WVmPZVSE8x0C1t+73dN5IfWoPSATolMhyXmcRDu/6oWEfPg344KqDgng0nUdlHSt6m0z0Q3/2jUk9+cP0ndfLeNRX/BeErjDT18wRjAAAAAElFTkSuQmCC");
          }
          .department{
            background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAADyElEQVRYR+2YW2gcZRSAvzOJ2WSDF1AQDFgQr9matWSyogj6oC8KvkR8iGK1pcWlGpUiWCQYr4kaUNAqFJRab4gggjUYlEjUB2Vm1xuKJagEVARpuhLjJSQ5cuJsnW538s9uYumDBxaWnfOf8825/OewwnEmcpzx8D+QKyNrjdA1wFPAb8B24GOXQ9fzZoFOBJ4AtsYcLAGPAcPAgstx0vNmgK4A9gIbEox+AdwEfN4MVCNAHcAIMAjOZrAI3Q88CljkUktaoAKwDzgvteV/FD8BNgMH0p5zAZ0A3AfcA7SkNVqj90d03opfXTZWA7oQeBHIu4ykfP4+cAsws5p+PSCLxN1RDbSldJZWbQ64E3g+bZedA7wAXJLWQ5N6+4FtwM+15+MRsnA+DWSbdNLosYPAzYDBHZY40O+AtfaxlG+AC5KALLd201pnHQuxcbMFeD0JyH6/KLpvrMP+S/kgSpeVx3XAS8C35rBel1lnPRB1mrfOVH8C9wJPAsvAp1EQ3gOuSgKqMlwaddzZ6wRVimbc1zF7Nop2RpCPu4DseSdgisU1QC0CDwMPAfa9VjLAX9UfXaNjRa+7u/v6mZmZPfPz8yc3AuZ53oHl5eUbAItOklwJWMpWxAkUhuHFqvrG3NzcGWNjY4yPjzuZRISBgQGKxeIvbW1ttxcKhddWOWT7k33cQEEQ2AK2W1UtrCsyNTXFyMgIs7OzdX10dXUxPDxMPv/vCBSRN7PZbDGXyx11MwPvVgs6MUKqKkEQ7E6qnUqlwujoKJOTk0dA9ff3Mzg4SEfH0feriBwSkdt8338ldsjmpr3Z4VKom7Jyudy9uLj4lSs3ExMTWBrb29sZGhqiULC1KVkMyvf9U0WkuoacGU3/0wAbJck1FATBFlW1+8L250RZWFigpaVl5eOA+U5ENvu+/1FM7zLgQ2AT8JmzqMvl8oalpaW9qmp7dNMiInuy2ezOXC5n4yIuA8DLwLXAW04gU7B6CsPwDlV9pNHhKyI/eZ63tbe3952Et9kFmN0dwDOpgKqGwjA8X1X3qWpfmlCJyKuZTGZHT0/PoVX0nwVuBUYBg3PfQ3FjqtpaKpV2qeqQqiZtBQc9zyv6vn/EFE+Aehu4OkrbjQ0DVY0GQbBJVW3fztU42t/Z2bkt4b6px/QlsBGw6X9500B2cHp6OlOpVB604aiq8yJyV19f33Np0hnT+RU4CfgeOGtNQFWjpVKpp7W1dTafz//QIIytNnb3nGLvB5y7LkANQtSqG8zpwI/RHxaNFfUanac67pz2qayso9Lfe4j8JXHam58AAAAASUVORK5CYII=");
          }
          .address{
            background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAADv0lEQVRYR82YX2gcVRSHvzOb3awxNSbQLBbaqAQVKxSKGESkD9o+WERfrMVSIQ9WJKLgk82mdSQ7URAVqz6UPgimWAhCqQ+K+uAfVIKgqFhaKBKttDa0xiZR3O4mc+Ruu2q7M3fu7PSh92ng/M653zn33rlnRrjChlxhPKQHCrTEX9wPbAYGEVYBHspJhJ+BD4BDBPJrO8m6A/naT43dwA4gb51MCBH2k2MXz8vxNGBuQGN6HyHvAD1pgiNU8XiMcdnv6pcMVNYngddQPNegLTqPcSpiqps47EBlfQDlILSx1y6d2mMHFdmXRBQPVNYB4EeU7qQgjvY6cAcT8p1NbwN6G2W742Suso+ZkE3pgXy9iTpHMu2buFmFuwnki3hzlGVUdwITCUf7DMIYHXzGEnVC7kKooKxO8NtDIE+nBfoKuDM2sPAbedbjy6mLNC9qDwtMA7dYfGcI5Ma0QHNAb2zQHA8zLlOR9l26gWU+tVapQCe+1KI0rZva1yI1/rYGXEEfO+WPSM2U5vieBZQuS5WuJ5Bf3IBe0F4WMRWKG3UKdMdl2HAaVbOUpdgIHrdSkSNuQKpmq9ZQOiwZDhHI15H253QNdSKz/1dfYCW+nHEDMqqyHkW52VKlTwi4BxFt0YzqAWCrJZmzVOiL9I29Esq6F23c6vFDOEgXT1CW2YbInLBFXkUZTvAzrcmD6U7Zbt3IEh9ZAxujaTPA7AVzLdxmXeZmsBzbbbe/7eow99jaRKg0AuEUeQZsByIeaEw3EfJhmvkStcKjBDJp3wk266i+AYwkTuQmeJcJeShJau+HzCugzOuXAWqKAtvwZSkbUNO7rC+jPJMULNIuTLKOYbbIsot/cgv7H9Q24CWU61wCI8xd6Ab24os5jU7DHciE87WLGlsRnkJZF1ORY8AeVjDJszLvRPE/UTqgpuMrehW/8x7KvRdNKHxDNxtjL14HuvaATOBLoS4DzPl3bZbRhDK9U8bKNDGyATUrtdBouM5myc0ZaOqwFk5XWeOFlDRkpcI1ohRDpYhHp3kWEBWqGlIVj3NC43lRPE53wGy+n+PDN0jVBbilQvumtVQTHkHZAI2TNKCabWlFUIUTAj+I8nkuz4HHb4/+5m8BenNap1UZcsmmbY0wMzLEoEjr+6kF6K0ZLVZn2bx8vkLrEQYF+rNUSQTTHf4kwrcqfMnVHBpZK39GJeS0qd8/pp0n5lhVV/pCj2tlmR7xKF74LdP8NWN6oroo5zTHvCrzHR5zvSVOblkt9o+GzC/Gttcq2fEfQwsnNOzpMb4AAAAASUVORK5CYII=");
          }
        }
      }
    }
  }
  .photo-wall{
    margin-bottom: 18px;
    ul{
      display: flex;
      flex-wrap: wrap;
    }
    li{
      width: 240px;
      height: 164px;
      margin-right: 10px;
      margin-bottom: 10px;
      cursor: pointer;
      &:nth-child(3n){
        margin-right: 0;
      }
      .el-image > img{
        border-radius: 4px;
      }
    }
  }
  .living-conditions{

  }
  .conditions-box{
    &-overview{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      color: #666;
      & > div{
        text-align: center;
        margin-right: 52px;
        margin-bottom: 20px;
        & > div{
          background: #f5f5f5;
          border-radius: 8px;
          overflow: hidden;
          width: 80px;
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 10px;
          img{
            background: #f5f5f5;
            width: 48px;
            height: 48px;
          }
        }
      }
    }
    &-detail{
      & > div{
        font-size: 16px;
        color: #666;
        margin-bottom: 20px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        line-clamp: 6;
        -webkit-box-orient: vertical;
        .detail-title{
          display: flex;
          align-items: center;
          font-size: 20px;
          color: #333;
          margin-bottom: 12px;
          i{
            width: 32px;
            height: 32px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background: #f5f5f5;
            border-radius: 3px;
            font-size: 20px;
            margin-right: 12px;
            &::before{
              content:'';
              width: 18px;
              height: 18px;
              background-size: 100% 100%!important;
            }
          }
        }
      }
    }
  }
  .icon-dormitory::before{
      background: url("https://static-data.gaokao.cn/icon/canteen_dormitory/dormitory_standard.png");
  }
  .icon-canteen::before{
      background: url("https://static-data.gaokao.cn/icon/canteen_dormitory/dormitory_standard.png");
  }
  .video{
    margin-bottom: 23px;
    .nodata{
      text-indent: 15px;
      color: #888;
      width: 100%;
      height: 80px;
      line-height: 80px!important;
      border: 1px solid #e4e4e4;
      text-align: center;
      font-size: 17px;
      font-weight: 200!important;
      img{
        width: 114px;
        aspect-ratio: auto 114 / 30;
        height: 30px;
        vertical-align: middle !important;
      }
    }
  }

  .content-right{
    float: right;
    width: 380px;
    margin-right: 20px;
  }
  .sex-ratio{
    margin-bottom: 31px;
    &-content{
      height: 130px;
      padding-top: 30px;
      text-align: center;
      box-shadow: 0 0 13px #e6e6e6;
      border-radius: 10px;
      box-sizing: border-box;
    }
  }
  .rate-box{
    float: left;
    width: 295px;
    margin: -2px 10px 0;
    &-num{
      display: flex;
      justify-content: space-between;
      font-size: 28px;
      font-weight: 700;
      color: #1ea5eb;
      opacity: .85;
      padding: 0 15px;
      & > span:last-child{
        float: right;
        color: #f96359;
      }
    }
    &-line{
      height: 10px;
      .line1{
        float: left;
        height: 100%;
        background: #1ea5eb;
        border-radius: 5px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      .line-center{
        float: left;
        background: #fff;
        width: 2px;
        height: 100%;
      }
      .line2{
        float: left;
        height: 100%;
        background: #f96359;
        border-radius: 5px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
  .job-census{
    margin-bottom: 31px;
    &-content{
      height: 170px;
      padding: 25px 25px;
      box-sizing: border-box;
      box-shadow: 0 0 13px #e6e6e6;
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      .job-item{
        width:32%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .job-circle{
          width: 90px;
          height: 90px;
          border: 1px solid #ffc351;
          border-radius: 50%;
          line-height: 90px;
          text-align: center;
          font-size: 18px;
          font-weight: 700;
          color: #ffc351;
        }
        .job-circle2{
          border: 1px solid #f96359;
          color: #f96359;
        }
        .job-circle3{
          border: 1px solid #13aff2;
          color: #13aff2;
        }
        .job-title{
          text-align: center;
          margin-top: 2px;
        }
      }
    }
  }
</style>
  