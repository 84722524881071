<template>
  <div class="school-enrollment">
    <div class="box-content">
      <ul class="list" v-if="dataList.length > 0">
        <li v-for="(item, index) in dataList" :key="index">
          <div>
            <router-link target='_blank' tag='a' :to="{ name: 'schoolArticleDetail', query: { id: item.id } }">{{ item.title }}</router-link>
          </div>
          <span>{{ item.updatetime }}</span>
        </li>
      </ul>
      <div class="nodata" v-else>
          <img src="@/assets/img/no_data.svg">
      </div>
      <div class="pagination">
        <el-pagination
          v-model:currentPage="currentPage"
          v-model:pageSize="pageSize"
          :small="small"
          :disabled="disabled"
          :background="background"
          prev-text="上一页"
          next-text="下一页"
          layout="prev, pager, next, jumper"
          :total="total"
          @current-change="handleCurrentPageChange"
        />
      </div>
    </div>
    
  </div>
</template>
  
<script>
 import { getSchoolEnrollment } from '@/api/home';

export default {
  name: 'SchoolEnrollment',
  props: {
    id: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      dataList: [],
      currentPage: 1,
      pageSize: 20,
      small: false,
      disabled: false,
      background: false,
      total: 0
    };
  },
  methods: {
    async fetchSchoolEnrollment(id) {
      try {
        const response = await getSchoolEnrollment({ id: id, page: this.currentPage, rows: this.pageSize });
        if (response.code === 1) {
          this.dataList = response.data.list;
          this.total = response.data.total;
        }
      } catch (error) {
        // 处理错误
      }
    },
    handleTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // 可选的，使用平滑滚动效果
      });
    },
    handleCurrentPageChange(newPage) {
      this.currentPage = newPage;
      this.fetchSchoolEnrollment(this.id);
      this.handleTop();
    }
  },
  mounted() {
    this.fetchSchoolEnrollment(this.id);
  }
};
</script>
<style lang="scss" scoped>
  .school-enrollment{
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    padding:50px 23px;
  }
  .box{
    &-content{
      padding: 0 40px;
    }
  }
  .list{
    min-height:500px; 
    & > li{
      height: 53px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      border-bottom: 1px solid #E5E5E5;
      font-size: 16px;
      line-height: 16px;
      font-weight: normal;
      letter-spacing: 0em;
      color: #222222;
      & > div{
        width: 80%;
        position: relative;
        padding-left: 15px;
        cursor: pointer;
        &::before{
          content: "";
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: #222222;
          margin: 0 5px 3.5px;
          position: absolute;
          left: 0;
          top: 6.5px;
        }
        a{
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          -ms-flex: 1;
          flex: 1;
          cursor: pointer;
          color: #222222;
          &:hover{
            text-decoration: underline;
            color: #C29881;
          }
        }
      }
    }
   
  }
  .pagination{
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
  .nodata{
      text-indent: 15px;
      color: #888;
      width: 100%;
      height: 80px;
      line-height: 80px!important;
      border: 1px solid #e4e4e4;
      text-align: center;
      font-size: 17px;
      font-weight: 200!important;
      img{
        width: 114px;
        aspect-ratio: auto 114 / 30;
        height: 30px;
        vertical-align: middle !important;
      }
    }
</style>
  