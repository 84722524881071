<template>
  <div class="carousel">
    <template v-if="images">
      <div class="carousel-container" :style="{ transform: `translateX(${translateValue}px)` }">
        <div v-for="(item, index) in images" :key="index" class="carousel-item">
          <img :src="item" alt="轮播图">
        </div>
      </div>
      
      <div class="bottom-btn" >
        <span :class="[translateValue === 0?'active':'']">视频</span>
        <span :class="[translateValue !== 0?'active':'']">校园风光</span>
        <!-- <span>校园生活</span> -->
      </div>
    </template>
    
    <template v-else>
      <div class="no-data">暂无内容</div>
    </template>
    <!-- <button @click="prevSlide" class="carousel-btn">上一个</button>
    <button @click="nextSlide" class="carousel-btn">下一个</button> -->
  </div>
</template>

<script>
import { toRefs,ref, onMounted, defineComponent } from 'vue';

export default defineComponent({
  props: {
    images:[]
  },
  setup(props) {
    const translateValue = ref(0);
    const currentSlide = ref(0);
    const carouselItems = ref(props.images);

    const nextSlide = () => {
      currentSlide.value = (currentSlide.value + 1) % carouselItems.value.length;
      translateValue.value = -currentSlide.value * 450;
    };

    const prevSlide = () => {

      currentSlide.value = (currentSlide.value - 1 + carouselItems.value.length) % carouselItems.value.length;
      translateValue.value = -currentSlide.value * 450;
    };

    // 每隔3秒自动切换到下一项
    const autoSlide = () => {
      setInterval(() => {
        nextSlide();
      }, 3000);
  };

    onMounted(() => {
      if(carouselItems.value){
        autoSlide();
      }
    });

    return {
      translateValue,
      nextSlide,
      prevSlide,
      carouselItems,
    };
  },
});
</script>

<style scoped>
.carousel {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 15px;
}

.carousel-container {
  width:100%;
  height:100%;
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.carousel-item {
  flex: 0 0 100%;
}

img {
  width: 100%;
  height: 100%;
}

.carousel-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px;
  background-color: #333;
  color: #fff;
  border: none;
  cursor: pointer;
}

.carousel-btn:hover {
  background-color: #555;
}

.bottom-btn{
  position: absolute;
  right: 12px;
  bottom: 12px;
  z-index: 100;
  background: rgba(0,0,0,.4);
  border-radius: 40px;
  padding: 4px 8px;
  & > span{
    display: inline-block;
    height: 24px;
    line-height: 24px;
    margin-right: 4px;
    padding: 0 8px;
    color: hsla(0,0%,100%,.88);
    font-size: 14px;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}
.active{
  background: #fff !important;
  color: #000 !important;
  border-radius: 50px !important;
}
.no-data{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #909399;
}
</style>
