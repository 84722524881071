<template>
  <div class="school-filter">
    <div class="search">
      <el-input class="input" v-model="key" placeholder="请输入院校名称"/>
      <el-button class="btn" type="danger" @click="search">搜索</el-button>
    </div>
    <div class="filter">
      <div class="filter-item">
        <div class="condition-name">院校所属</div>
        <div class="condition-box">
          <span :class="condition?.province_name === ''?'active':''" @click="seleced_province_name('')">全部</span>
          <div>
            <span :class="[item === condition?.province_name?'active':'']" v-for="(item,index) in data.province_name" :key="index" @click="seleced_province_name(item)">{{item}}</span>
          </div>
        </div>
      </div>

      <div class="filter-item">
        <div class="condition-name">院校类型</div>
        <div class="condition-box">
          <span :class="condition?.subject_name === ''?'active':''" @click="seleced_subject_name('')">全部</span>
          <div>
            <span :class="[item === condition?.subject_name?'active':'']" v-for="(item,index) in data.subject_name" :key="index" @click="seleced_subject_name(item)">{{item}}</span>
          </div>
        </div>
      </div>

      <div class="filter-item">
        <div class="condition-name">办学类型</div>
        <div class="condition-box">
          <span :class="condition?.nature_name === ''?'active':''" @click="seleced_nature_name('')">全部</span>
          <div>
            <span :class="[item === condition?.nature_name?'active':'']" v-for="(item,index) in data.nature_name" :key="index" @click="seleced_nature_name(item)">{{item}}</span>
          </div>
        </div>
      </div>

      <div class="filter-item">
        <div class="condition-name">院校特色</div>
        <div class="condition-box">
          <span :class="condition?.characteristic === ''?'active':''" @click="seleced_characteristic('')">全部</span>
          <div>
            <span :class="[item === condition?.characteristic?'active':'']" v-for="(item,index) in schoolCharacteristic" :key="index" @click="seleced_characteristic(item)">{{item}}</span>
          </div>
        </div>
      </div>

      <!-- <div class="filter-item">
        <div class="condition-name">办学类型</div>
        <div class="condition-box">
          <span :class="condition?.type_name === ''?'active':''" @click="seleced_type_name('')">全部</span>
          <div>
            <span :class="[item === condition?.type_name?'active':'']" v-for="(item,index) in data.type_name" :key="index" @click="seleced_type_name(item)">{{item}}</span>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
  
<script>
  export default {
    name: 'SchoolFilter',
    components: {
      
    },
    props: {
      data: {
        type: [Array,Object],
        required: true
      },
      condition:{
        type: Object,
      },
    },
    data() {
      return {
        // //所属省份
        // city: [
        //   "北京市",
        //   "天津市",
        //   "河北省",
        //   "山西省",
        //   "内蒙古自治区",
        //   "辽宁省",
        //   "吉林省",
        //   "黑龙江省",
        //   "上海市",
        //   "江苏省",
        //   "浙江省",
        //   "安徽省",
        //   "福建省",
        //   "江西省",
        //   "山东省",
        //   "河南省",
        //   "湖北省",
        //   "湖南省",
        //   "广东省",
        //   "广西壮族自治区",
        //   "海南省",
        //   "重庆市",
        //   "四川省",
        //   "贵州省",
        //   "云南省",
        //   "西藏自治区",
        //   "陕西省",
        //   "甘肃省",
        //   "青海省",
        //   "宁夏回族自治区",
        //   "新疆维吾尔自治区",
        //   "香港特别行政区",
        //   "澳门特别行政区" 
        // ],
        // //院校类型
        // schoolType:[
        //   '综合','理工','农林','医药','师范','语言','财经','政法','体育','艺术','民族','军事','其他'
        // ],
        // eduType: [
        //   '普通本科','专科（高职）','公办','民办','中外合作办学','内地与港澳台地区合作办学'
        // ],
        schoolCharacteristic: [
          '985','211','双一流','教育部直属','中央部委','强基计划','双高计划'
        ],
        key: '',
        province_name:'',
        subject_name:'',
        nature_name:'',
        type_name:'',
        characteristic:'',

      }
    },
    mounted() {
      
    },
    methods: {
      search(e){
        this.condition.key = this.key;
        this.public_emit();
      },
      seleced_province_name(data){
        this.condition.province_name = data;
        this.public_emit();
      },
      seleced_subject_name(data){
        this.condition.subject_name = data;
        this.public_emit();
      },
      seleced_nature_name(data){
        this.condition.nature_name = data;
        this.public_emit();
      },
      seleced_type_name(data){
        this.condition.type_name = data;
        this.public_emit();
      },
      seleced_characteristic(data){
        this.condition.characteristic = data;
        this.public_emit();
      },
      public_emit(){
        // const condition = 
        // {
        //   'key' : this.key,
        //   'province_name' : this.province_name,
        //   'subject_name' : this.subject_name,
        //   'nature_name' : this.nature_name,
        //   'characteristic' : this.characteristic,
        // };
                          // console.log(condition);
        this.$emit('returnData', this.condition);
      }
    }
  }
</script>
<style lang="scss" scoped>
  .school-filter{
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 1px;
    font-size: 14px;
    padding: 40px 0 35px 27px;
    box-sizing: border-box;
    color: #666;
    margin-bottom: 8px;
  }
  .search{
    margin-bottom: 47px;
  }
  .input{
    width: 255px;
    height: 43px;
  }
  .btn{
    width: 65px;
    height: 43px;
    margin-left: 20px;
  }
  .filter{
    &-item{
      display: flex;
      margin-bottom: 20px;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
  .condition-name{
    color: #8E8E8E;
  }
  .active{
    color: #F15F2D !important;
  }
  .condition-box{
    display: inline-flex;
    flex: 1;
    & > span{
      min-width: 28px;
      box-sizing: content-box;
    }
    span{
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
      font-size: 14px;
      color: #585959;
      // padding: 0 0 0 20px;
      margin-left: 20px;
      cursor: pointer;
      display: inline-block;
      margin-bottom: 8px;
      transition: all .3s;
    }
    & > div{
      flex: 1;
    }
  }
</style>
  